import React, { memo } from 'react';
import { Col, Form, Button, InputGroup } from 'react-bootstrap';

const Uid = ({ uid, openSkinGuideProduct }) => (
  <Form.Group controlId="productUid" as={Form.Row}>
    <Form.Label column sm={4} md={3} xl={2}>
      UID
    </Form.Label>

    <Col>
      <InputGroup className="flex-nowrap">
        <InputGroup.Prepend className="w-75">
          <InputGroup.Text id="basic-addon3" className="w-100 align-left">
            {uid}
          </InputGroup.Text>
        </InputGroup.Prepend>

        <InputGroup.Append className="w-25">
          <Button className="outline-secondary w-100" onClick={openSkinGuideProduct}>
            View in SG
          </Button>
        </InputGroup.Append>
      </InputGroup>
    </Col>
  </Form.Group>
);

export default memo(Uid);
