import React, { memo } from 'react';
import { Form } from 'react-bootstrap';

const BrandsSelect = ({ brand, brands, setBrand }) => (
  <Form.Group>
    <Form.Control
      as="select"
      value={brand}
      onChange={(e) => {
        setBrand(e.target.value);
      }}
    >
      <option value="">Any brand ({brands.length} total)</option>

      {brands.map((brand) => (
        <option key={brand.name} value={brand.name}>
          ({brand.count}) {brand.name}
        </option>
      ))}
    </Form.Control>
  </Form.Group>
);

export default memo(BrandsSelect);
