import React from 'react';
import { Row, Col, Button } from 'react-bootstrap';
import { Intersect } from 'react-bootstrap-icons';

import TablePagination from '^/components/table-pagination';

const TableNavigation = ({
  pagination,
  onPageChange,
  mergeProducts,
  onMergeProductsClick,
  onUnselectProductsClick,
}) => {
  const shouldRenderMergeButton = mergeProducts.length > 1;
  const shouldRenderUnselectButton = mergeProducts.length > 0;

  return (
    <Row noGutters className="align-items-center">
      <TablePagination pagination={pagination} onPageChange={onPageChange} />

      {shouldRenderMergeButton && (
        <Col xs="auto" className="ml-2">
          <Button size="sm" variant="outline-secondary" onClick={onMergeProductsClick}>
            <Intersect /> Merge {mergeProducts.length} products
          </Button>
        </Col>
      )}

      {shouldRenderUnselectButton && (
        <Col xs="auto" className="ml-2">
          <Button
            size="sm"
            onClick={onUnselectProductsClick}
            variant="link"
            className="text-muted px-0"
          >
            Unselect {mergeProducts.length} products
          </Button>
        </Col>
      )}
    </Row>
  );
};

export default TableNavigation;
