import React, { memo } from 'react';
import { Row, Col, Form } from 'react-bootstrap';
import { Check, FlagFill, Archive } from 'react-bootstrap-icons';

import ChangelogInfoIcon from '../changelog-info-icon';

const Checkboxes = ({
  note,
  setNote,
  flagged,
  verified,
  archived,
  setFlagged,
  setArchived,
  setVerified,
}) => (
  <>
    <Form.Group controlId="verified" as={Row}>
      <Col sm={{ offset: 4 }} md={{ offset: 3 }} xl={{ offset: 2 }}>
        <Form.Check
          type="switch"
          label={
            verified ? (
              <>
                Verified <Check key="check" />
              </>
            ) : (
              'Not verified'
            )
          }
          checked={verified}
          onChange={() => setVerified(!verified)}
          className={verified ? 'text-success' : null}
        />
      </Col>
    </Form.Group>

    <Form.Group controlId="archived" as={Row}>
      <Col sm={{ offset: 4 }} md={{ offset: 3 }} xl={{ offset: 2 }}>
        <Form.Check
          type="switch"
          label={
            <>
              <ChangelogInfoIcon />{' '}
              {archived ? (
                <>
                  Archived <Archive key="archived" className="text-danger" />
                </>
              ) : (
                'Not archived '
              )}
            </>
          }
          checked={archived}
          onChange={() => setArchived(!archived)}
          className={archived ? 'text-danger' : null}
        />
      </Col>
    </Form.Group>

    <Form.Group controlId="flagged" as={Row}>
      <Col sm={{ offset: 4 }} md={{ offset: 3 }} xl={{ offset: 2 }}>
        <Form.Check
          type="switch"
          label={
            flagged ? (
              <>
                Flagged <FlagFill key="flag" className="text-danger" />
              </>
            ) : (
              'Not flagged'
            )
          }
          checked={flagged}
          onChange={() => setFlagged(!flagged)}
          className={flagged ? 'text-danger' : null}
        />
      </Col>
    </Form.Group>

    {flagged && (
      <Form.Group controlId="note" as={Form.Row}>
        <Form.Label column sm={4} md={3} xl={2}>
          Note
        </Form.Label>

        <Col>
          <Form.Control
            value={note}
            onChange={(e) => setNote(e.target.value)}
            placeholder="Note (visible to admins only)"
          />
        </Col>
      </Form.Group>
    )}
  </>
);

export default memo(Checkboxes);
