import React, { memo } from 'react';
import { Table } from 'react-bootstrap';
import classnames from 'classnames';

import useInciNameOptions from './hooks/use-inci-name-options';

const IngredientInciNameTable = ({ ingredient, sourceGroups, onClick, className }) => {
  const inciNameOptions = useInciNameOptions({ ingredient, sourceGroups });

  return (
    <Table bordered hover className={classnames(className)}>
      <tbody>
        {inciNameOptions.map((el, idx) => (
          <tr
            key={idx}
            onClick={() => onClick(el)}
            className={classnames({ 'table-info': el.key === 'original' })}
          >
            <td>
              <small>
                <b>{el.sourceName}</b>

                {!!el.inciName && ` - ${el.inciName}`}
              </small>

              <br />

              {el.inciName}
            </td>
          </tr>
        ))}
      </tbody>
    </Table>
  );
};

export default memo(IngredientInciNameTable);
