import React from 'react';
import { BoxArrowUpRight } from 'react-bootstrap-icons';

const SourceProducts = ({ sourceProducts, sourceName }) =>
  sourceProducts
    .filter((p) => p.sourceName === sourceName)
    .map((p) => (
      <p key={p.sourceId} className="mb-0">
        •{' '}
        <a href={p.sourceUrl}>
          {p.name}{' '}
          <sup>
            <BoxArrowUpRight />
          </sup>
        </a>
      </p>
    ));

export default SourceProducts;
