import React, { useState, useEffect } from 'react';
import { Container, Row, Col } from 'react-bootstrap';

import datumAPI from '^/api/datum';

import PerformanceCard from '^/components/performance-card';
import { ProductModal } from '^/components/modals';
import { FiltersForm, SkinParamsForm, FeatureProfileForm } from '^/components/forms';

import ProductRecommendation from '../product-recommendation';

function IngredientMlDashboard() {
  const [isLoading, setIsLoading] = useState(true);
  const [productRecommendations, setProductRecommendations] = useState([]);
  const [selectedProduct, setSelectedProduct] = useState();

  const [skinProfile, setSkinProfile] = useState({
    tone: 0,
    milieu: 0,
    hydration: 0,
    smoothness: 0,
    shineControl: 0,
    dermalFibers: 0,
    oxygenSaturation: 0,
  });

  const [filterParams, setFilterParams] = useState({
    priority: '',
    exclude: '',
  });

  const [skinFeatures, setSkinFeatures] = useState({
    "active-acne": 0,
    "bitterness-lines": 0,
    "clogged-pores": 0,
    "coarse-skin": 0,
    "coef-A_low": 0,
    "corneometer_low": 0,
    "dryness-lines": 0,
    "duec-hemoglobin": 0,
    "duec-melanin": 0,
    "expression-lines": 0,
    "forehead-lines": 0,
    "hbt1": 0,
    "hbt2": 0,
    "ph": 0,
    "photoaged-skin": 0,
    "phototype": 0,
    "phototype_low": 0,
    "pigmentation_A": 0,
    "pigmentation_I": 0,
    "pih": 0,
    "pores_A": 0,
    "pores_I": 0,
    "porphyrin_A": 0,
    "porphyrin_I": 0,
    "redness_A": 0,
    "redness_I": 0,
    "sagging": 0,
    "sebum": 0,
    "sebum_low": 0,
    "sensitive-skin": 0,
    "sto2_low": 0,
    "sun-damage": 0,
    "temperature": 0,
    "tewl": 0,
    "texture": 0,
    "undereye-bags": 0,
    "uv-exposure": 0,
    "veins": 0,
    "wrinkles_A": 0,
    "wrinkles_I": 0
  });

  function applyResponse({ data }) {
    setIsLoading(false);
    setProductRecommendations(data);
  }

  function loadProducts(skinProfile, skinFeatures, filters) {
    setIsLoading(true);
    datumAPI.getProductRecommendations({ skinProfile, skinFeatures, filters }).then(applyResponse);
  }

  useEffect(() => {
    loadProducts(skinProfile, skinFeatures, filterParams);
    // eslint-disable-next-line
  }, [skinProfile, skinFeatures, filterParams]);

  return (
    <Container fluid>
      <Row className="mb-4">
        <Col>
          <SkinParamsForm skinProfile={skinProfile} onChange={setSkinProfile} />
        </Col>

        <div className="w-100"></div>

        <Col>
          <FeatureProfileForm skinFeatures={skinFeatures} onChange={setSkinFeatures} />
        </Col>

        <div className="w-100"></div>

        <Col>
          <FiltersForm onChange={setFilterParams} filterParams={filterParams} />
        </Col>
      </Row>

      <Row>
        <Col>
          <h3 className="border-bottom mb-3">Products</h3>
        </Col>
      </Row>

      {isLoading ? (
        <p className="text-center mt-5">Loading...</p>
      ) : (
        productRecommendations.map((recommendation) => (
          <Row key={recommendation.product.uid} className="mx-n1">
            <Col md={8} className="mb-5 px-1">
              <ProductRecommendation
                className="h-100"
                recommendation={recommendation}
                onProductClick={(product) =>
                  setSelectedProduct({
                    ...product,
                    performanceVector: recommendation.performanceVector,
                  })
                }
              />
            </Col>

            <Col className="mb-5 px-1">
              <PerformanceCard
                className="h-100"
                performanceVector={recommendation.performanceVector}
              />
            </Col>
          </Row>
        ))
      )}

      {!!selectedProduct && (
        <ProductModal product={selectedProduct} onHide={() => setSelectedProduct(null)} />
      )}
    </Container>
  );
}

export default IngredientMlDashboard;
