import React, { memo } from 'react';
import { Col, Form } from 'react-bootstrap';

import ChangelogInfoIcon from '../changelog-info-icon';

const CoverImages = ({ productCoverImageUrl, imageUrls, disabled, setProductCoverImageUrl }) => (
  <Form.Group controlId="productCoverImage" as={Form.Row}>
    <Form.Label column sm={4} md={3} xl={2}>
      Cover image <ChangelogInfoIcon />
    </Form.Label>

    <Col>
      <div className="form-control-plaintext">
        <div className="d-flex mx-n2 mb-n2 align-items-start flex-wrap">
          {imageUrls.map((imageUrl) => (
            <img
              key={imageUrl}
              alt=""
              src={imageUrl}
              style={{
                cursor: 'pointer',
                maxWidth: '100px',
                maxHeight: '100px',
                objectFit: 'contain',
                borderWidth: '2px',
              }}
              onClick={() => !disabled && setProductCoverImageUrl(imageUrl)}
              className={`img-thumbnail d-block mx-2 mb-2${
                imageUrl === productCoverImageUrl ? ' border-success' : ''
              }`}
            />
          ))}
        </div>
      </div>
    </Col>
  </Form.Group>
);

export default memo(CoverImages);
