import React, { memo } from 'react';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';

import TagBadge from '^/components/tag-badge';

function Ingredients(productIngredients, term) {
  const tags = productIngredients.map(({ slug, raw }) => [
    <OverlayTrigger
      key={slug}
      overlay={
        <Tooltip id={`ing-tooltip-${slug}`}>
          <small>{slug}</small>
        </Tooltip>
      }
      placement="top"
    >
      <TagBadge variant={slug === term ? 'success' : 'light'}>{raw}</TagBadge>
    </OverlayTrigger>,
    ' ',
  ]);

  return tags;
}

export default memo(Ingredients);
