import React from 'react';
import { Row, Col, Form } from 'react-bootstrap';

const RangeInput = ({ title, labels: [label1, label2], onChange, disabled }) => {
  return (
    <Form.Group>
      <Row className="justify-content-between">
        <Col>
          <Form.Label className="small">{title}</Form.Label>
        </Col>

        <div className="w-100"></div>

        <Col>
          <Form.Control
            type="range"
            className="custom-range border-0"
            min={-4}
            max={4}
            disabled={disabled}
            onMouseUp={(e) => onChange(e.target.value)}
          />
        </Col>

        <div className="w-100"></div>

        <Col className="small font-weight-light">
          <small>{label1}</small>
        </Col>

        <Col className="small font-weight-light text-right">
          <small>{label2}</small>
        </Col>
      </Row>
    </Form.Group>
  );
};

export default RangeInput;
