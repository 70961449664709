import React, { memo } from 'react';

function HighlightText(text, substr) {
  if (!text) {
    return;
  }

  const arr = text.split(substr);

  if (arr.length > 1) {
    const result = arr.flatMap((part, idx) => [
      part,
      <span key={`match-${idx}`} className="bg-success text-white">
        {substr}
      </span>,
    ]);

    return result.slice(0, -1);
  } else {
    return text;
  }
}

export default memo(HighlightText);
