import React, { useState, useEffect } from 'react';
import { ChevronUp, ChevronDown } from 'react-bootstrap-icons';
import { Button, Modal } from 'react-bootstrap';

import datumAPI from '^/api/datum';

import { humanizeSourceName } from '^/helpers/ingredient';

import { IngredientForm } from '^/components/forms';

import SourceIngredientCard from './components/source-ingredient-card';

var MESSAGE_TIMER_REF = null;

function IngredientModal({ ingredient: ingr, onHide }) {
  const [ingredient, setIngredient] = useState(ingr);
  const [isProcessing, setProcessing] = useState(false);
  const [lastSaveStatus, setLastSaveStatus] = useState(null);
  const [isSourceGroupsVisible, setSourceGroupsVisible] = useState(false);

  const sourceGroupsObj = ingredient.sources.reduce((acc, ing) => {
    const { sourceName, claims } = ing;
    acc[sourceName] = acc[sourceName] || {
      key: sourceName,
      name: humanizeSourceName(sourceName),
      sources: [],
      claims: [],
    };
    acc[sourceName].sources.push(ing);
    claims.forEach((claim) => {
      if (acc[sourceName].claims.indexOf(claim) === -1) {
        acc[sourceName].claims.push(claim);
      }
    });

    return acc;
  }, {});

  const sourceGroups = Object.values(sourceGroupsObj);

  async function handleSubmit(ingredient) {
    setProcessing(true);
    try {
      const { data } = await datumAPI.updateIngredient(ingredient);
      setIngredient(data);
      setLastSaveStatus('success');
    } catch (error) {
      console.log(error);
      setLastSaveStatus('error');
    } finally {
      setProcessing(false);
      MESSAGE_TIMER_REF = setTimeout(() => {
        setLastSaveStatus(null);
      }, 3000);
    }
  }

  useEffect(() => {
    return function cleanup() {
      if (MESSAGE_TIMER_REF) {
        clearTimeout(MESSAGE_TIMER_REF);
      }
    };
  }, []);

  return (
    <Modal show={true} dialogClassName="modal-w-90" onHide={onHide}>
      <Modal.Header closeButton>
        <Modal.Title as="h5">Ingredient details</Modal.Title>
      </Modal.Header>

      <Modal.Body>
        <IngredientForm
          onSubmit={handleSubmit}
          disabled={isProcessing}
          processing={isProcessing}
          ingredient={ingredient}
          message={
            { success: ['success', 'Saved.'], error: ['error', 'Failed.'] }[lastSaveStatus] || []
          }
          sourceGroups={sourceGroups}
        />
      </Modal.Body>

      <Modal.Footer className="d-block">
        <Button
          variant="link"
          className="text-muted px-0"
          onClick={() => setSourceGroupsVisible(!isSourceGroupsVisible)}
        >
          {isSourceGroupsVisible
            ? ['Hide sources ', <ChevronUp key="chu" />]
            : ['Show sources ', <ChevronDown key="chd" />]}
        </Button>

        {isSourceGroupsVisible
          ? sourceGroups
              .map(({ sources }) => sources)
              .flat()
              .map((source, idx) => (
                <SourceIngredientCard
                  key={`${idx}-${source.sourceUrl}`}
                  className="mb-3"
                  sourceIngredient={source}
                />
              ))
          : null}
      </Modal.Footer>
    </Modal>
  );
}

export default IngredientModal;
