import React, { memo } from 'react';
import { Col, Form } from 'react-bootstrap';

import TagList from '^/components/tag-list';
import ProductIngredients from '^/components/product-ingredients';

const Ingredients = ({ productIngredients, ingredientClaims }) => (
  <>
    <Form.Group controlId="productIngredients" as={Form.Row}>
      <Form.Label column sm={4} md={3} xl={2}>
        Ingredients
      </Form.Label>

      <Col>
        <div className="form-control-plaintext">
          <ProductIngredients ingredients={productIngredients} />
        </div>
      </Col>
    </Form.Group>

    <Form.Group controlId="productIngredientClaims" as={Form.Row}>
      <Form.Label column sm={4} md={3} xl={2}>
        Ingredient claims
      </Form.Label>

      <Col>
        <div className="form-control-plaintext">
          <TagList tags={ingredientClaims} variant="light" />
        </div>
      </Col>
    </Form.Group>
  </>
);

export default memo(Ingredients);
