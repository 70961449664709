import React, { useState } from 'react';
import { Button } from 'react-bootstrap';

import TagList from '^/components/tag-list';
import IngredientBadges from '^/components/ingredients-badges';
import { IngredientModal } from '^/components/modals';

const InciNameRow = ({ ingredient }) => {
  const { inciName, slug, claims } = ingredient;
  const [modalVisible, setModalVisible] = useState(false);

  return (
    <tr>
      <td>
        <Button onClick={() => setModalVisible(true)} variant="link" className="p-0 text-left">
          <IngredientBadges ingredient={ingredient} />

          {inciName}
        </Button>

        {modalVisible && (
          <IngredientModal ingredient={ingredient} onHide={() => setModalVisible(false)} />
        )}
      </td>

      <td>{slug}</td>

      <td>
        <TagList tags={claims} />
      </td>
    </tr>
  );
};

export default InciNameRow;
