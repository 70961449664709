import React, { memo, useState } from 'react';
import { Button } from 'react-bootstrap';

import datumAPI from '^/api/datum';

import TagList from '^/components/tag-list';
import IngredientBadges from '^/components/ingredients-badges';
import { IngredientModal, IngredientMatchesModal, SourceProductsModal } from '^/components/modals';

function loadIngredientMatches(ingredientSlug) {
  return datumAPI.matchIngredientSlug(ingredientSlug);
}

function loadProductsForIngredient(ingredientSlug) {
  return datumAPI.getSourceProductsWithIngredient(ingredientSlug);
}

const IngredientRow = ({ rowNumber, offset, ingredient: { slug, count, ingredient } }) => {
  const [showModal, setShowModal] = useState();

  return (
    <tr className={!!ingredient ? null : 'table-danger'}>
      <td>{rowNumber + offset}</td>

      <td style={{ maxWidth: '300px' }}>
        {ingredient && (
          <Button
            variant="link"
            onClick={() => setShowModal('ingredient')}
            className="p-0 text-left"
          >
            <IngredientBadges ingredient={ingredient} /> {ingredient.inciName}
          </Button>
        )}
      </td>

      <td style={{ maxWidth: '300px' }}>
        <Button
          variant="link"
          onClick={() => setShowModal('ingredients')}
          className="p-0 text-left"
        >
          {slug}
        </Button>
      </td>

      <td>
        <Button variant="link" className="p-0 text-left" onClick={() => setShowModal('products')}>
          {count}
        </Button>

        {showModal === 'ingredients' && (
          <IngredientMatchesModal
            data={() => loadIngredientMatches(slug)}
            onHide={() => setShowModal(null)}
            ingredientSlug={slug}
          />
        )}

        {showModal === 'products' && (
          <SourceProductsModal
            data={() => loadProductsForIngredient(slug)}
            onHide={() => setShowModal(null)}
            ingredientSlug={slug}
          />
        )}

        {showModal === 'ingredient' && (
          <IngredientModal ingredient={ingredient} onHide={() => setShowModal(null)} />
        )}
      </td>

      <td>
        <TagList tags={ingredient ? ingredient.claims : []} />
      </td>
    </tr>
  );
};

export default memo(IngredientRow);
