import { useCallback } from 'react';

import { ARCHIVED_VALUES_MAP } from '../constants';

export default function useSubmitCallback({ state, onSubmit, productPulls }) {
  return useCallback(
    (e) => {
      e.preventDefault();
      e.stopPropagation();

      const tagNames = state.tags.map(({ name }) => name);
      const selectedProductPull = productPulls[state.productPullIndex];

      const data = {
        ...state,
        tags: tagNames,
        archived: ARCHIVED_VALUES_MAP[state.archived],
        productPull: selectedProductPull,
      };

      onSubmit(data);
    },
    [state, onSubmit, productPulls],
  );
}
