import React, { useState } from 'react';
import { Button, FormCheck } from 'react-bootstrap';

import { PRODUCT_SOURCES } from '^/constants/product';

import TagList from '^/components/tag-list';
import { ProductModal } from '^/components/modals';

import ProductBadges from '../product-badges';
import CategoryBadge from '../category-badge';
import SourceProducts from '../source-products';

const ProductRow = ({ product, onCheck, isChecked, rowNumber }) => {
  const [isModalVisible, setIsModalVisible] = useState(false);
  const imageUrl = product.coverImageUrl;

  return (
    <tr key={product.uid}>
      <td className="text-center align-middle">
        <FormCheck onChange={() => onCheck(product)} checked={isChecked} />
      </td>

      <td className="text-center align-middle">{rowNumber}</td>

      <td className="text-center align-middle">
        {imageUrl && (
          <img
            alt=""
            src={imageUrl}
            style={{ maxWidth: '50px', maxHeight: '50px', objectFit: 'contain' }}
          />
        )}
      </td>

      <td>{product.brand}</td>

      <td>
        <Button variant="link" className="p-0 text-left" onClick={() => setIsModalVisible(true)}>
          <ProductBadges product={product} /> {product.name}
        </Button>

        {isModalVisible && (
          <ProductModal product={product} onHide={() => setIsModalVisible(false)} />
        )}
      </td>

      <td>{!!product.category && <CategoryBadge>{product.category}</CategoryBadge>}</td>

      <td>
        <TagList tags={product.tags} />
      </td>

      {PRODUCT_SOURCES.map((sourceName) => (
        <td key={sourceName}>
          <SourceProducts sourceName={sourceName} sourceProducts={product.sourceProducts} />
        </td>
      ))}
    </tr>
  );
};

export default ProductRow;
