import React, { memo } from 'react';
import { Pagination } from 'react-bootstrap';
import classnames from 'classnames';

const CursorPagination = ({ nextCursor, prevCursor, onPageChange, className }) => (
  <Pagination size="sm" className={classnames(className)}>
    <Pagination.Item onClick={() => onPageChange(prevCursor)} disabled={!prevCursor}>
      Prev
    </Pagination.Item>

    <Pagination.Item onClick={() => onPageChange(nextCursor)} disabled={!nextCursor}>
      Next
    </Pagination.Item>
  </Pagination>
);

export default memo(CursorPagination);
