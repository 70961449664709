import { useMemo } from 'react';

export default function useSortedBrandOptions(brandOptions) {
  return useMemo(
    () =>
      brandOptions.sort((a, b) => {
        const nameA = a.name.toLowerCase();
        const nameB = b.name.toLowerCase();

        if (nameA < nameB) {
          return -1;
        }

        if (nameA > nameB) {
          return 1;
        }

        return 0;
      }),
    [brandOptions],
  );
}
