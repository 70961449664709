import React, { useState } from 'react';
import { Modal } from 'react-bootstrap';

import Matches from './components/matches';

function IngredientMatchesModal({ ingredientSlug, data, onHide }) {
  const [matches, setMatches] = useState(null);

  function doLoad() {
    data().then(({ data }) => {
      setMatches(data);
    });
  }

  return (
    <Modal size="lg" show={true} onShow={doLoad} onHide={onHide}>
      <Modal.Header closeButton>
        <Modal.Title as="h5">
          Best matches for <span>"{ingredientSlug}"</span>
        </Modal.Title>
      </Modal.Header>

      {matches ? (
        <Matches matches={matches} ingredientSlug={ingredientSlug} />
      ) : (
        <Modal.Body className="text-center">Loading...</Modal.Body>
      )}
    </Modal>
  );
}

export default IngredientMatchesModal;
