import React, { memo } from 'react';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';

import TagList from '^/components/tag-list';
import TagBadge from '^/components/tag-badge';

const ProductIngredients = ({ ingredients: productIngredients }) => {
  const count = productIngredients.length;

  if (count > 0) {
    const totalTag = (
      <TagBadge key="total" variant="dark">
        TOTAL: {count}
      </TagBadge>
    );

    const tags = productIngredients.flatMap(({ slug, raw }, idx) => [
      <OverlayTrigger
        key={`${slug}-${idx}`}
        placement="top"
        overlay={
          <Tooltip id={`ing-tooltip-${slug}`}>
            <small>{slug}</small>
          </Tooltip>
        }
      >
        <TagBadge variant="light">{raw}</TagBadge>
      </OverlayTrigger>,
      ' ',
    ]);

    tags.push(totalTag);

    return tags;
  } else {
    return <TagList tags={[]} />;
  }
};

export default memo(ProductIngredients);
