import React from 'react';
import { Table } from 'react-bootstrap';
import classnames from 'classnames';

import IngredientRow from './components/ingredient-row';

const ProductIngredientsTable = ({ productIngredients, offset, className }) => {
  return (
    <Table bordered striped hover size="sm" className={classnames(className)}>
      <thead>
        <tr>
          <th></th>
          <th>INCI name</th>
          <th>Slug</th>
          <th>Products</th>
          <th>Claims</th>
        </tr>
      </thead>

      <tbody>
        {productIngredients.map((i, idx) => (
          <IngredientRow key={i.slug} offset={offset} rowNumber={idx + 1} ingredient={i} />
        ))}
      </tbody>
    </Table>
  );
};

export default ProductIngredientsTable;
