import React from 'react';
import { ProgressBar } from 'react-bootstrap';

const SkinParamBar = ({ label, value }) => {
  const opacity = value < 10 ? 0.1 : value > 80 ? 1 : value / 100;

  return (
    <div className="progress-bar-skin-effect-bar">
      <ProgressBar style={{ height: '8px' }}>
        <ProgressBar now={value} style={{ opacity }} />
      </ProgressBar>

      <p className="progress-bar-skin-effect-label text-center text-uppercase small font-weight-bold text-muted">
        {label}
      </p>
    </div>
  );
};

export default SkinParamBar;
