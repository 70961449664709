import axios from 'axios';
import { Socket } from 'phoenix';

import CONFIG from '^/constants/config';

let SOCKET;

class DatumAPI {
  constructor(baseURL = '') {
    this.baseURL = baseURL;

    const params = {
      baseURL,
    };

    if (CONFIG.API_BACKEND_AUTH_USERNAME && CONFIG.API_BACKEND_AUTH_PASSWORD) {
      params.auth = {
        username: CONFIG.API_BACKEND_AUTH_USERNAME,
        password: CONFIG.API_BACKEND_AUTH_PASSWORD,
      };
    }

    this.axios = axios.create(params);
  }

  getSocket() {
    if (!SOCKET) {
      SOCKET = new Socket(`${this.baseURL.replace('http', 'ws')}/socket`, {});
      SOCKET.connect();
    }

    return SOCKET;
  }

  async listProducts(params) {
    const { data } = await this.axios.get('/api/private/products', { params });
    return data;
  }

  async updateProduct(uid, attrs) {
    const { data } = await this.axios.put(`/api/private/products/${uid}`, {
      product: attrs,
    });
    return data;
  }

  async listProductBrands() {
    const { data } = await this.axios.get('/api/private/product-brands');
    return data;
  }

  async listProductTags() {
    const { data } = await this.axios.get('/api/private/product-tags');
    return data;
  }

  async listProductPulls() {
    const { data } = await this.axios.get('/api/private/product-pulls');
    return data;
  }

  async getProductRawIngredients(productId) {
    const { data } = await this.axios.get(`/api/private/products/${productId}/raw-ingredients`);
    return data;
  }

  async getProductSkinEffect(productId) {
    const { data } = await this.axios.get(`/api/private/products/${productId}/skin-effect`);
    return data;
  }

  async listIngredientClaims() {
    const { data } = await this.axios.get('/api/private/ingredient-claims');
    return data;
  }

  async listIngredients(params) {
    const { data } = await this.axios.get('/api/private/ingredients', { params });
    return data;
  }

  async listProductIngredients(params) {
    const { data } = await this.axios.get('/api/private/product-ingredients', { params });
    return data;
  }

  async matchIngredientSlug(slug) {
    const { data } = await this.axios.get(`/api/private/ingredients/match/${slug}`);
    return data;
  }

  async updateIngredient(ingredient) {
    const { slug } = ingredient;
    const { data } = await this.axios.put(`/api/private/ingredients/${slug}`, {
      ingredient,
    });
    return data;
  }

  async getProductIngredientsCoverageStats() {
    const { data } = await this.axios.get('/api/private/product-ingredients-stats');
    return data;
  }

  async getSourceProductsWithIngredient(ingredientSlug) {
    const { data } = await this.axios.get(
      `/api/private/ingredients/${ingredientSlug}/source-products`,
    );
    return data;
  }

  async getProductRecommendations(params) {
    const { data } = await this.axios.post('/api/private/product-recommendations', params);
    return data;
  }

  async mergeProducts({ uid: masterProductUid }, slaveProducts) {
    const params = { slaveProductUids: slaveProducts.map((p) => p.uid) };
    const { data } = await this.axios.put(
      `/api/private/products/${masterProductUid}/merge`,
      params,
    );
    return data;
  }
}

export default new DatumAPI(CONFIG.API_BACKEND_URL);
