import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';

const Home = () => (
  <Container fluid>
    <Row>
      <Col>
        <h1>Datum web interface</h1>
      </Col>
    </Row>
  </Container>
);

export default Home;
