import { LockFill, FlagFill, Archive, DropletHalf, ClockHistory } from 'react-bootstrap-icons';

const BADGES = [
  {
    id: 'archived',
    Icon: Archive,
    title: 'Archived',
    className: 'text-danger',
    shouldRender: ({ archived }) => archived,
  },
  {
    id: 'verified',
    Icon: LockFill,
    title: 'Verified',
    className: 'text-success',
    shouldRender: ({ verified }) => verified,
  },
  {
    id: 'has-changelog',
    Icon: ClockHistory,
    title: 'Has changelog',
    className: 'text-muted',
    shouldRender: ({ changelogs }) => Array.isArray(changelogs) && changelogs.length > 0,
  },
  {
    id: 'flagged',
    Icon: FlagFill,
    title: 'Flagged',
    className: 'text-danger',
    shouldRender: ({ flagged }) => flagged,
  },
  {
    id: 'no-ingredients',
    Icon: DropletHalf,
    title: 'No ingredients',
    className: 'text-danger',
    shouldRender: ({ productIngredients }) =>
      !(Array.isArray(productIngredients) && productIngredients.length > 0),
  },
];

export { BADGES };
