import React, { useState, useEffect } from 'react';
import { Row, Col, Form, Button, Spinner, Dropdown, ButtonGroup } from 'react-bootstrap';

import { parseLastSeenAt } from '^/helpers/date';

import Uid from './components/uid';
import Tags from './components/tags';
import Field from './components/field';
import Checkboxes from './components/checkboxes';
import Ingredients from './components/ingredients';
import CoverImages from './components/cover-images';

function ProductForm({ product, tagOptions, disabled, isProcessing, onSubmit, lastSaveStatus }) {
  const [submitMode, setSubmitMode] = useState('save');

  const [note, setNote] = useState(product.note || '');
  const [flagged, setFlagged] = useState(!!product.flagged);
  const [verified, setVerified] = useState(!!product.verified);
  const [archived, setArchived] = useState(!!product.archived);
  const [productTags, setProductTags] = useState(product.tags || []);
  const [productName, setProductName] = useState(product.name || '');
  const [productBrand, setProductBrand] = useState(product.brand || '');
  const [productPrice, setProductPrice] = useState(product.price || '');
  const [productCategory, setProductCategory] = useState(product.category || '');
  const [productDescription, setProductDescription] = useState(product.description || '');
  const [productCoverImageUrl, setProductCoverImageUrl] = useState(product.coverImageUrl);

  useEffect(() => {
    if (flagged) {
      setVerified(false);
      setSubmitMode('save');
    } else {
      setNote('');
    }
  }, [flagged]);

  useEffect(() => {
    if (verified) {
      setFlagged(false);
    }
  }, [verified]);

  function handleSubmit(e) {
    e.preventDefault();
    e.stopPropagation();

    const attrs = {
      name: productName,
      brand: productBrand,
      price: productPrice,
      description: productDescription,
      category: productCategory,
      tags: productTags.map((tag) => (typeof tag === 'object' ? tag.label : tag)),
      coverImageUrl: productCoverImageUrl,
      verified,
      archived,
      flagged,
      note,
    };

    if (submitMode === 'verifyAndSave') {
      attrs.verified = true;
      setVerified(attrs.verified);
    }

    onSubmit && onSubmit(attrs);
  }

  const openSkinGuideProduct = () => {
    const isStaging =
      window.location.hostname.includes('staging.') ||
      window.location.hostname.includes('localhost');

    const win = window.open(
      `https://${isStaging ? 'staging.' : 'www.'}skinguide.co/skincare/products/${product.uid}`,
      '_blank',
    );
    win.focus();
  };

  const isLastSaveStatusSuccess = lastSaveStatus === 'success';

  return (
    <Form onSubmit={handleSubmit}>
      <fieldset disabled={disabled}>
        <Uid uid={product.uid} openSkinGuideProduct={openSkinGuideProduct} />

        <Field
          label="Last seen at"
          value={parseLastSeenAt(product.lastSeenAt)}
          readOnly
          controlId="productLastSeenAt"
        />

        <CoverImages
          disabled={disabled}
          imageUrls={product.imageUrls}
          productCoverImageUrl={productCoverImageUrl}
          setProductCoverImageUrl={setProductCoverImageUrl}
        />

        <Field
          label="Name"
          value={productName}
          withIcon
          onChange={(e) => setProductName(e.target.value)}
          controlId="productName"
          placeholder="Product name"
        />

        <Field
          label="Brand"
          value={productBrand}
          withIcon
          onChange={(e) => setProductBrand(e.target.value)}
          controlId="productBrand"
          placeholder="Product brand"
        />

        <Field
          type="number"
          step="0.01"
          label="Price"
          value={productPrice}
          withIcon
          onChange={(e) => setProductPrice(e.target.value)}
          controlId="productPrice"
          placeholder="Product price"
        />

        <Field
          as="textarea"
          rows={5}
          label="Description"
          value={productDescription}
          withIcon
          onChange={(e) => setProductDescription(e.target.value)}
          controlId="productDescription"
          placeholder="Product description"
        />

        <Field
          as="select"
          label="Category"
          value={productCategory}
          withIcon
          onChange={(e) => setProductCategory(e.target.value)}
          children={
            <>
              <option value=""></option>
              <option value="moisturizer">Moisturizer</option>
              <option value="treatment">Treatment</option>
              <option value="cleanser">Cleanser</option>
              <option value="toner">Toner</option>
              <option value="makeup">Makeup</option>
              <option value="sunscreen">Sunscreen</option>
            </>
          }
          controlId="productCategory"
        />

        <Tags
          disabled={disabled}
          tagOptions={tagOptions}
          productTags={productTags}
          setProductTags={setProductTags}
        />

        <Ingredients
          ingredientClaims={product.ingredientClaims}
          productIngredients={product.productIngredients}
        />

        <hr />

        <Checkboxes
          note={note}
          setNote={setNote}
          flagged={flagged}
          verified={verified}
          archived={archived}
          setFlagged={setFlagged}
          setArchived={setArchived}
          setVerified={setVerified}
        />

        <Row>
          <Col sm={{ offset: 4 }} md={{ offset: 3 }} xl={{ offset: 2 }}>
            <Dropdown as={ButtonGroup} alignRight>
              <Button
                type="submit"
                style={{ minWidth: '140px' }}
                variant={submitMode === 'verifyAndSave' ? 'success' : 'outline-success'}
              >
                {isProcessing ? (
                  <>
                    <Spinner animation="grow" as="span" size="sm" key="spinner" /> Processing...
                  </>
                ) : (
                  { verifyAndSave: 'Verify and Save', save: 'Save' }[submitMode]
                )}
              </Button>

              <Dropdown.Toggle
                id="submitButton"
                split
                variant={submitMode === 'verifyAndSave' ? 'success' : 'outline-success'}
              />

              <Dropdown.Menu>
                <Dropdown.Item onClick={() => setSubmitMode('verifyAndSave')} disabled={flagged}>
                  Verify and Save
                </Dropdown.Item>
                <Dropdown.Item onClick={() => setSubmitMode('save')}>Save</Dropdown.Item>
              </Dropdown.Menu>
            </Dropdown>

            {lastSaveStatus !== null && (
              <span className={`${isLastSaveStatusSuccess ? 'text-muted' : 'text-danger'} ml-3`}>
                {isLastSaveStatusSuccess ? 'Saved.' : 'Failed.'}
              </span>
            )}
          </Col>
        </Row>
      </fieldset>
    </Form>
  );
}

export default ProductForm;
