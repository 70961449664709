import React from 'react';
import { LockFill, FlagFill, CardText, Archive } from 'react-bootstrap-icons';

const IngredientBadges = ({ ingredient: { verified, flagged, description, archived } }) => (
  <>
    {archived && <Archive className="text-danger" />}

    {verified && <LockFill className="text-success" />}

    {flagged && <FlagFill className="text-danger" />}

    {!!description && <CardText className="text-success" />}
  </>
);

export default IngredientBadges;
