const ARCHIVED_STATES_MAP = {
  ALL: 'all',
  ARCHIVED: 'archived',
  UNARCHIVED: 'unarchived',
};

const ARCHIVED_VALUES_MAP = {
  [ARCHIVED_STATES_MAP.ALL]: undefined,
  [ARCHIVED_STATES_MAP.ARCHIVED]: true,
  [ARCHIVED_STATES_MAP.UNARCHIVED]: false,
};

export { ARCHIVED_STATES_MAP, ARCHIVED_VALUES_MAP };
