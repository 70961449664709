import React, { Fragment, useState } from 'react';
import { Modal } from 'react-bootstrap';

import Products from './components/products';

function SourceProductsModal({ ingredientSlug, data, onHide }) {
  const [products, setProducts] = useState(null);

  function doLoad() {
    data().then(({ data }) => {
      setProducts(data);
    });
  }

  return (
    <Modal size="lg" show={true} onShow={doLoad} onHide={onHide}>
      <Fragment>
        <Modal.Header closeButton>
          <Modal.Title as="h5">Products containing "{ingredientSlug}"</Modal.Title>
        </Modal.Header>

        {products ? (
          <Products products={products} ingredientSlug={ingredientSlug} />
        ) : (
          <Modal.Body className="text-center">Loading...</Modal.Body>
        )}
      </Fragment>
    </Modal>
  );
}

export default SourceProductsModal;
