const SKIN_DICTIONARY = {
  tone: 'Tone',
  milieu: 'Milieu',
  hydration: 'Hydration',
  smoothness: 'Smoothness',
  shineControl: 'Shine control',
  dermalFibers: 'Dermal fibers',
  oxygenSaturation: 'Oxygen saturation',
};

export { SKIN_DICTIONARY };
