import React, { memo } from 'react';
import { Col } from 'react-bootstrap';

import CursorPagination from '^/components/cursor-pagination';

export function TablePagination({
  pagination: { next, prev, totalCount, totalCountCapped },
  onPageChange,
}) {
  return (
    <>
      <Col xs="auto">
        <CursorPagination
          className="mb-0"
          nextCursor={next}
          prevCursor={prev}
          onPageChange={onPageChange}
        />
      </Col>

      <Col xs="auto" className="ml-2">
        Total: {totalCount}
        {totalCountCapped ? '+' : null}
      </Col>
    </>
  );
}

export default memo(TablePagination);
