import React, { memo, useState } from 'react';
import { Button, ListGroup } from 'react-bootstrap';
import { BoxArrowUpRight } from 'react-bootstrap-icons';

import datumAPI from '^/api/datum';

import Ingredients from '../ingredients';
import HighlightText from '../highlight-text';

const Product = ({ product, highlightedIngredientSlug }) => {
  const [isExpanded, setExpanded] = useState(false);
  const [rawIngredients, setRawIngredients] = useState();
  const [isLoading, setLoading] = useState(false);

  function loadRawIngredients() {
    setLoading(true);

    datumAPI.getProductRawIngredients(product.uid).then(({ data }) => {
      setRawIngredients(data);
      setLoading(false);
    });
  }

  function expandAndloadRawIngredients() {
    if (!isExpanded && !rawIngredients) {
      loadRawIngredients();
    }

    setExpanded(!isExpanded);
  }

  const highlightedProductIngredient = product.productIngredients.find(
    ({ slug }) => slug === highlightedIngredientSlug,
  );

  return (
    <ListGroup.Item>
      <Button
        style={{ cursor: 'default' }}
        variant="link"
        onClick={() => expandAndloadRawIngredients()}
        className="p-0 d-block w-100 text-left"
      >
        {product.name} ({product.sourceName})
      </Button>

      {isExpanded ? (
        <div>
          <hr />
          <p>
            <b>External URL:</b>{' '}
            <a href={product.sourceUrl} target="_blank" rel="noopener noreferrer">
              {product.name} <BoxArrowUpRight />
            </a>
          </p>
          <p>
            <b>Ingredients:</b>

            <br />

            <Ingredients
              ingredients={product.productIngredients}
              highlightedIngredientSlug={highlightedIngredientSlug}
            />
          </p>
          <p style={{ whiteSpace: 'pre-wrap' }}>
            <b>Raw ingredients:</b>
            <br />

            {isLoading ? (
              'Loading...'
            ) : (
              <HighlightText
                raw={highlightedProductIngredient.raw}
                rawIngredients={rawIngredients}
              />
            )}
          </p>
        </div>
      ) : null}
    </ListGroup.Item>
  );
};

export default memo(Product);
