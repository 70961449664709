import React, { memo } from 'react';
import { Table } from 'react-bootstrap';
import classnames from 'classnames';

import useDescriptionOptions from './hooks/use-description-options';

const IngredientDescription = ({ ingredient, sourceGroups, onClick, className }) => {
  const descriptionOptions = useDescriptionOptions({ ingredient, sourceGroups });

  const isEmpty = (description) => description === '' || !description;

  return (
    <Table bordered hover className={classnames(className)}>
      <tbody>
        {descriptionOptions.map((el, index) => (
          <tr
            key={index}
            onClick={() => onClick(el)}
            className={classnames({ 'table-info': el.key === 'original' })}
          >
            <td>
              <small>
                <b>{el.sourceName}</b>

                {!!el.inciName && ` - ${el.inciName}`}
              </small>

              <br />

              <span style={{ whiteSpace: 'pre-wrap' }}>
                {isEmpty(el.description) ? <i>Empty</i> : el.description}
              </span>
            </td>
          </tr>
        ))}
      </tbody>
    </Table>
  );
};

export default memo(IngredientDescription);
