import React, { memo, useState } from 'react';
import { Modal, CardDeck, Button, Spinner } from 'react-bootstrap';

import ProductCard from './components/product-card';

function MergeProductsModal({ mergeProducts, products, onHide }) {
  const [primaryProduct, setPrimaryProduct] = useState();
  const [isProcessing, setProcessing] = useState(false);

  const handleSubmit = async () => {
    setProcessing(true);

    const slaveProducts = products.filter((p) => p.uid !== primaryProduct.uid);

    try {
      await mergeProducts(primaryProduct, slaveProducts);

      onHide('merged');
    } catch (error) {
      console.log(error);
      alert('Failed to merge products');
    } finally {
      setProcessing(false);
    }
  };

  return (
    <Modal
      size={products.length > 4 ? 'xl' : 'lg'}
      show={true}
      onHide={() => !isProcessing && onHide()}
    >
      <Modal.Header closeButton>
        <Modal.Title as="h5">Merge products</Modal.Title>
      </Modal.Header>

      <Modal.Body>
        <CardDeck>
          {products.map((product) => (
            <ProductCard
              key={product.uid}
              product={product}
              onClick={(p) => !isProcessing && setPrimaryProduct(p)}
              isPrimary={primaryProduct?.uid === product.uid}
            />
          ))}
        </CardDeck>
      </Modal.Body>

      <Modal.Footer className="justify-content-start">
        {primaryProduct ? (
          <>
            <Button variant="success" onClick={handleSubmit} disabled={isProcessing}>
              Merge {products.length} products{' '}
              {isProcessing && <Spinner animation="grow" size="sm" />}
            </Button>

            <Button variant="secondary" onClick={onHide} disabled={isProcessing}>
              Close
            </Button>
          </>
        ) : (
          <p className="text-muted">Please select primary product</p>
        )}
      </Modal.Footer>
    </Modal>
  );
}

export default memo(MergeProductsModal);
