import React from 'react';
import { Switch, Route } from 'react-router-dom';

import Home from '^/pages/home';
import ProductsDashboard from '^/pages/products-dashboard';
import PipelinesDashboard from '^/pages/pipelines-dashboard';
import IngredientsDashboard from '^/pages/ingredients-dashboard';
import IngredientMlDashboard from '^/pages/ingredient-ml-dashboard';
import ProductIngredientsDashboard from '^/pages/product-ingredients-dashboard';
import ProductIngredientsCoverageStats from '^/pages/product-ingredients-coverage-stats';

import { ROUTES } from './constants';

const Router = () => (
  <Switch>
    <Route exact path={ROUTES.ROOT} component={Home} />
    <Route path={ROUTES.PRODUCTS} component={ProductsDashboard} />
    <Route path={ROUTES.PIPELINES} component={PipelinesDashboard} />
    <Route path={ROUTES.INGREDIENTS} component={IngredientsDashboard} />
    <Route path={ROUTES.INGREDIENT_ML} component={IngredientMlDashboard} />
    <Route path={ROUTES.PRODUCT_INGREDIENTS} component={ProductIngredientsDashboard} />
    <Route path={ROUTES.PRODUCT_INGREDIENTS_STATS} component={ProductIngredientsCoverageStats} />
  </Switch>
);

export default Router;
