import React from 'react';
import { Container, Row, Col, Form } from 'react-bootstrap';

import datumAPI from '^/api/datum';

import CursorPagination from '^/components/cursor-pagination';
import { IngredientSearchForm } from '^/components/forms';
import { ProductIngredientsTable } from '^/components/tables';

class ProductIngredientsDashboard extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      channel: null,
      isLoading: false,
      stats: null,
      filter: {
        unmatchedOnly: false,
      },
      pagination: {
        next: null,
        prev: null,
        totalCount: null,
        totalCountCapped: false,
      },
      productIngredients: [],
      currentPage: 1,
      claims: [{ name: 'skin-conditioning', count: 1 }],
    };
  }

  async componentDidMount() {
    this.joinChannel();

    const [{ data: claims }] = await Promise.all([datumAPI.listIngredientClaims(), this.load({})]);

    this.setState({ claims });
  }

  componentWillUnmount() {
    const { channel } = this.state;
    channel.leave();
  }

  joinChannel = () => {
    const socket = datumAPI.getSocket();
    const channel = socket.channel('ingredients');

    this.setState({ channel });
    channel
      .join()
      .receive('error', ({ reason }) => console.log('Failed join "ingredients" channel', reason))
      .receive('timeout', () => console.log('Networking issue. Still waiting...'));

    channel.on('ingredient_updated', ({ data: updatedIngredient }) => {
      const { productIngredients } = this.state;
      const idx = productIngredients.map(({ slug }) => slug).indexOf(updatedIngredient.slug);

      if (idx > -1) {
        const newProductIngredients = [...productIngredients];
        newProductIngredients[idx] = { ...productIngredients[idx], ingredient: updatedIngredient };
        this.setState({ productIngredients: newProductIngredients });
      }
    });
  };

  load = async ({ cursor, filter }) => {
    this.setState({ isLoading: true });

    const { data: productIngredients, pagination, stats } = await datumAPI.listProductIngredients({
      cursor,
      filter,
    });

    this.setState({
      isLoading: false,
      stats,
      pagination,
      productIngredients,
    });
  };

  handleToggleUnmatchedOnly = () => {
    const { filter } = this.state;
    const newFilter = { ...filter, unmatchedOnly: !filter.unmatchedOnly };

    this.setState({ filter: newFilter });
    this.load({ filter: newFilter });
  };

  handleSearchFormSubmit = ({ inciName, slug, tags, tagJoinType, skinParam }) => {
    const { filter } = this.state;

    const map = {
      all: 'claimsAll',
      any: 'claimsAny',
      excludeAll: 'claimsExcludeAll',
      excludeAny: 'claimsExcludeAny',
    };
    const tagsKey = map[tagJoinType];
    const newFilter = { ...filter, inciName, slug, [tagsKey]: tags, skinParam };
    this.setState({ filter: newFilter });
    this.load({ filter: newFilter });
  };

  renderPagination = () => {
    const {
      filter,
      pagination: { next, prev },
    } = this.state;

    const onPageChange = (cursor) => {
      this.load({ cursor, filter });
    };

    return (
      <CursorPagination
        className="mb-3"
        nextCursor={next}
        prevCursor={prev}
        onPageChange={onPageChange}
      />
    );
  };

  render() {
    const { isLoading, productIngredients, claims, filter, pagination, stats } = this.state;

    return (
      <Container className="mb-5" fluid>
        <Row>
          <Col>
            <IngredientSearchForm tagOptions={claims} onSubmit={this.handleSearchFormSubmit} />
          </Col>

          <Col sm={2}>
            {!!stats && (
              <p className="text-right">
                <b>
                  <span className="text-success">{stats.matchedCount}</span> / {stats.totalCount}
                </b>{' '}
                <small>({((stats.matchedCount / stats.totalCount) * 100).toFixed(2)}%)</small>
              </p>
            )}
          </Col>
        </Row>

        <Row>
          <Col>
            {isLoading ? (
              <p className="text-muted text-center mt-5">Loading...</p>
            ) : (
              <div>
                <Row>
                  <Col xs="auto">{this.renderPagination()}</Col>
                  <Col>
                    <Form.Check
                      id="filterUnmatched"
                      type="checkbox"
                      label="Show unmatched only"
                      checked={filter.unmatchedOnly}
                      onChange={this.handleToggleUnmatchedOnly}
                    />
                  </Col>
                </Row>

                <ProductIngredientsTable
                  offset={pagination.offset}
                  className="mb-3"
                  productIngredients={productIngredients}
                />

                {this.renderPagination()}
              </div>
            )}
          </Col>
        </Row>
      </Container>
    );
  }
}

export default ProductIngredientsDashboard;
