const TAG_TYPES_MAP = {
  ALL: 'all',
  ANY: 'any',
  EXCLUDE_ALL: 'excludeAll',
  EXCLUDE_ANY: 'excludeAny',
};

const TAG_TYPES_DICTIONARY = {
  [TAG_TYPES_MAP.ALL]: 'With all',
  [TAG_TYPES_MAP.ANY]: 'With any',
  [TAG_TYPES_MAP.EXCLUDE_ALL]: 'Without all',
  [TAG_TYPES_MAP.EXCLUDE_ANY]: 'Without any',
};

const TAG_TYPES = [
  {
    type: TAG_TYPES_MAP.ALL,
    isIncludedAll: true,
    isWithSomething: true,
  },
  {
    type: TAG_TYPES_MAP.ANY,
    isIncludedAll: false,
    isWithSomething: true,
  },
  {
    type: TAG_TYPES_MAP.EXCLUDE_ALL,
    isIncludedAll: true,
    isWithSomething: false,
  },
  {
    type: TAG_TYPES_MAP.EXCLUDE_ANY,
    isIncludedAll: false,
    isWithSomething: false,
  },
];

export { TAG_TYPES, TAG_TYPES_MAP, TAG_TYPES_DICTIONARY };
