import React, { memo } from 'react';
import { Form } from 'react-bootstrap';

const CategoriesSelect = ({ category, setCategory }) => (
  <Form.Group>
    <Form.Control
      as="select"
      value={category}
      onChange={(e) => setCategory(e.target.value)}
      placeholder="Category"
    >
      <option value="">Category</option>
      <option value="moisturizer">Moisturizer</option>
      <option value="treatment">Treatment</option>
      <option value="cleanser">Cleanser</option>
      <option value="toner">Toner</option>
      <option value="makeup">Makeup</option>
      <option value="sunscreen">Sunscreen</option>
      <option value="--UNSET--">--UNSET--</option>
    </Form.Control>
  </Form.Group>
);

export default memo(CategoriesSelect);
