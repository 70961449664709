import { ROUTES } from '^/router/constants';

import { getId } from '^/helpers/id';

export const NAVIGATION_LINKS = [
  {
    id: getId(),
    to: ROUTES.ROOT,
    name: 'Home',
    className: 'pl-0',
  },
  {
    id: getId(),
    to: ROUTES.PRODUCTS,
    name: 'Products',
  },
  {
    id: getId(),
    to: ROUTES.INGREDIENTS,
    name: 'INCI',
  },
  {
    id: getId(),
    to: ROUTES.PRODUCT_INGREDIENTS,
    name: 'Product ingredients',
  },
  {
    id: getId(),
    to: ROUTES.PRODUCT_INGREDIENTS_STATS,
    name: 'Product ingredients coverage',
  },
  {
    id: getId(),
    to: ROUTES.INGREDIENT_ML,
    name: 'Ingredient-ML',
  },
  {
    id: getId(),
    to: ROUTES.PIPELINES,
    name: 'Pipelines',
  },
];
