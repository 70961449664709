import React from 'react';
import { Form, Button, Col } from 'react-bootstrap';

import useCustomState from './hooks/use-custom-state';
import useSubmitCallback from './hooks/use-submit-callback';
import useSortedProductPulls from './hooks/use-sorted-product-pulls';
import useSortedBrandOptions from './hooks/use-sorted-brand-options';

import TagTypes from './components/tag-types';
import NameInput from './components/name-input';
import SearchInput from './components/search-input';
import BrandsSelect from './components/brands-select';
import ArchivedSelect from './components/archived-select';
import CategoriesSelect from './components/categories-select';
import ProductPullIndexSelect from './components/product-pull-index-select';

function ProductSearchForm({ onSubmit, brandOptions, tagOptions, productPulls }) {
  const {
    state,

    setTags,
    setName,
    setSlug,
    setBrand,
    setArchived,
    setCategory,
    setTagJoinType,
    setIsFormExpanded,
    setProductPullIndex,
  } = useCustomState();

  const sortedBrandOptions = useSortedBrandOptions(brandOptions);
  const sortedProductPulls = useSortedProductPulls(productPulls);
  const handleSubmit = useSubmitCallback({ state, onSubmit, productPulls: sortedProductPulls });

  const {
    tags,
    slug,
    name,
    brand,
    archived,
    category,
    tagJoinType,
    isFormExpanded,
    productPullIndex,
  } = state;

  return (
    <Form onSubmit={handleSubmit}>
      {isFormExpanded ? (
        <Form.Row>
          <Col xs="12" sm="6" md="4" lg="2">
            <BrandsSelect brand={brand} brands={sortedBrandOptions} setBrand={setBrand} />
          </Col>

          <Col xs="12" sm="6" md="4" lg="2">
            <NameInput name={name} setName={setName} />
          </Col>

          <Col xs="12" sm="6" md="4" lg="2">
            <CategoriesSelect category={category} setCategory={setCategory} />
          </Col>

          <Col xs="12" sm="6" md="4" lg="2">
            <TagTypes
              tags={tags}
              setTags={setTags}
              tagOptions={tagOptions}
              tagJoinType={tagJoinType}
              setTagJoinType={setTagJoinType}
            />
          </Col>

          <Col xs="12" sm="6" md="4" lg="2">
            <ArchivedSelect archived={archived} setArchived={setArchived} />
          </Col>

          <Col xs="12" sm="6" md="4" lg="2">
            <ProductPullIndexSelect
              productPulls={productPulls}
              productPullIndex={productPullIndex}
              setProductPullIndex={setProductPullIndex}
            />
          </Col>

          <Col xs="12" sm="6" md="4" lg="2">
            <Form.Group>
              <Button type="submit" className="btn-block">
                Search
              </Button>
            </Form.Group>
          </Col>
        </Form.Row>
      ) : (
        <SearchInput slug={slug} setSlug={setSlug} setIsFormExpanded={setIsFormExpanded} />
      )}
    </Form>
  );
}

export default ProductSearchForm;
