const INGREDIENTS_SOURCE_NAME_DICTIONARY = {
  'file://tgsc.jsonl': 'The Good Scents Company',
  'file://chemberry.jsonl': 'Chemberry',
  'file://prospector.jsonl': 'Prospector',
  'file://cosing-colorants.jsonl': 'CosIng (colorants)',
  'file://cosing-ingredients.jsonl': 'CosIng',
  'file://specialchem-products.jsonl': 'SpecialChem (products)',
  'file://specialchem-ingredients.jsonl': 'SpecialChem',
};

export { INGREDIENTS_SOURCE_NAME_DICTIONARY };
