import React from 'react';
import { Modal, ListGroup } from 'react-bootstrap';

import Product from '../product';

const Products = ({ products, ingredientSlug }) => (
  <Modal.Body>
    <ListGroup>
      {products.map((product) => (
        <Product key={product.uid} product={product} highlightedIngredientSlug={ingredientSlug} />
      ))}
    </ListGroup>
  </Modal.Body>
);

export default Products;
