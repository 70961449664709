import React, { useState, memo } from 'react';
import { Row, Col, Card, Button } from 'react-bootstrap';
import classnames from 'classnames';

import { humanizeSkinParam } from '^/helpers/skin-parameters';

import TagList from '^/components/tag-list';
import TagBadge from '^/components/tag-badge';

const ProductRecommendation = ({
  className,
  recommendation: { score, product, allergens, ingredientsVector },
  onProductClick,
}) => {
  const [selectedSkinParam, setSelectedSkinParam] = useState(null);
  const isAllergen = allergens.length > 0;

  let highlightedInciNames = [];

  if (selectedSkinParam) {
    highlightedInciNames = ingredientsVector[selectedSkinParam].map(({ slug }) => slug);
  }

  return (
    <Card className={classnames(className)}>
      <Row noGutters>
        <Col md={2} className="text-center">
          <div className="p-3">
            <img
              src={product.coverImageUrl}
              alt=""
              style={{ maxHeight: '200px', maxWidth: '100%' }}
            />
          </div>
        </Col>

        <Col md={10}>
          <Card.Body>
            <Card.Title className="text-muted">{product.brand}</Card.Title>
            <Card.Subtitle>
              <Button variant="link" className="p-0" onClick={() => onProductClick(product)}>
                {product.name}
              </Button>{' '}
              {isAllergen && <TagList tags={['allergen']} variant="danger" pill />}
              {Array.isArray(product.tags) && (
                <TagList tags={product.tags} pill variant="secondary" renderEmpty={false} />
              )}
            </Card.Subtitle>

            <Card.Text>
              <TagBadge pill>Score: {score.toFixed(3)}</TagBadge>{' '}
              {Object.entries(ingredientsVector).map(([skinParam]) => {
                const enabled = ingredientsVector[skinParam].length > 0;

                return [
                  <TagBadge
                    key={skinParam}
                    pill
                    variant={
                      enabled
                        ? selectedSkinParam && selectedSkinParam !== skinParam
                          ? 'light'
                          : 'info'
                        : selectedSkinParam && selectedSkinParam !== skinParam
                        ? 'light'
                        : 'secondary'
                    }
                    onMouseEnter={() => enabled && setSelectedSkinParam(skinParam)}
                    onMouseLeave={() => enabled && setSelectedSkinParam(null)}
                  >
                    {humanizeSkinParam(skinParam)}
                  </TagBadge>,
                  ' ',
                ];
              })}
            </Card.Text>

            <Card.Text>
              {product.productIngredients.map(({ slug }) => {
                return [
                  <TagBadge
                    key={slug}
                    pill
                    variant={
                      highlightedInciNames.includes(slug)
                        ? 'info'
                        : allergens.find((allergen) => allergen.slug === slug) &&
                          highlightedInciNames.length === 0
                        ? 'danger'
                        : 'light'
                    }
                  >
                    {slug}
                  </TagBadge>,
                  ' ',
                ];
              })}
            </Card.Text>
          </Card.Body>
        </Col>
      </Row>
    </Card>
  );
};

export default memo(ProductRecommendation);
