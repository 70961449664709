import { useMemo } from 'react';

export default function useSourceProducts(sourceProducts) {
  return useMemo(() => {
    return sourceProducts.slice().sort(({ sortName: sortNameA }, { sortName: sortNameB }) => {
      if (sortNameA > sortNameB) {
        return -1;
      }

      if (sortNameA < sortNameB) {
        return 1;
      }

      return 0;
    });
  }, [sourceProducts]);
}
