import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';

import datumAPI from '^/api/datum';

import TablePagination from '^/components/table-pagination';
import { IngredientsTable } from '^/components/tables';
import { IngredientSearchForm } from '^/components/forms';

class IngredientDashboard extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      filter: {},
      claims: [],
      channel: null,
      isLoading: true,
      pagination: {
        next: null,
        prev: null,
        totalCount: null,
        totalCountCapped: false,
      },
      ingredients: [],
    };
  }

  async componentDidMount() {
    this.joinChannel();

    const { filter } = this.state;

    const [{ data: claims }] = await Promise.all([
      datumAPI.listIngredientClaims(),
      this.load({ filter }),
    ]);

    this.setState({ claims });
  }

  componentWillUnmount() {
    const { channel } = this.state;
    channel.leave();
  }

  joinChannel = () => {
    const socket = datumAPI.getSocket();
    const channel = socket.channel('ingredients');

    this.setState({ channel });
    channel
      .join()
      .receive('error', ({ reason }) => console.log('Failed join "ingredients" channel', reason))
      .receive('timeout', () => console.log('Networking issue. Still waiting...'));

    channel.on('ingredient_updated', ({ data: updatedIngredient }) => {
      const { ingredients } = this.state;
      const idx = ingredients.map(({ slug }) => slug).indexOf(updatedIngredient.slug);

      if (idx > -1) {
        const newIngredients = [...ingredients];
        newIngredients[idx] = updatedIngredient;
        this.setState({ ingredients: newIngredients });
      }
    });
  };

  load = async ({ cursor, filter }) => {
    this.setState({ isLoading: true });

    const { data: ingredients, pagination } = await datumAPI.listIngredients({ cursor, filter });

    this.setState({
      isLoading: false,
      pagination,
      ingredients,
    });
  };

  handleSearchFormSubmit = ({ inciName, slug, tags, tagJoinType, skinParam }) => {
    const map = {
      all: 'claimsAll',
      any: 'claimsAny',
      excludeAll: 'claimsExcludeAll',
      excludeAny: 'claimsExcludeAny',
    };
    const tagsKey = map[tagJoinType];
    const filter = { inciName, slug, [tagsKey]: tags, skinParam };

    this.setState({ filter });

    this.load({ filter });
  };

  onPageChange = (cursor) => {
    const { filter } = this.state;

    this.load({ cursor, filter });
  };

  render() {
    const { ingredients, claims, pagination } = this.state;

    return (
      <Container fluid>
        <Row>
          <Col>
            <IngredientSearchForm tagOptions={claims} onSubmit={this.handleSearchFormSubmit} />
          </Col>
        </Row>

        <Row>
          <Col>
            <Row noGutters className="align-items-center mb-3">
              <TablePagination pagination={pagination} onPageChange={this.onPageChange} />
            </Row>
          </Col>
        </Row>

        <Row>
          <Col>
            <IngredientsTable ingredients={ingredients} />
          </Col>
        </Row>
      </Container>
    );
  }
}

export default IngredientDashboard;
