import React from 'react';
import { Button, Spinner } from 'react-bootstrap';

const ScheduleButton = ({ task, onClick }) => {
  const { status } = task;
  const isIdle = status === 'idle';

  return (
    <Button
      size="sm"
      block
      style={{ width: '60px' }}
      onClick={() => onClick(task)}
      disabled={!isIdle}
      className="px-0"
    >
      {isIdle ? 'Run' : <Spinner key="spinner" animation="grow" size="sm" />}
    </Button>
  );
};

export default ScheduleButton;
