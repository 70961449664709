import React from 'react';
import { ClockHistory } from 'react-bootstrap-icons';

const ChangelogInfoIcon = () => (
  <sup title="All changes are recorded in product changelog and survive DB rebuild">
    <ClockHistory />
  </sup>
);

export default ChangelogInfoIcon;
