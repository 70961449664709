import React, { useState, Fragment } from 'react';
import { Form, Button, InputGroup, Col, Dropdown, DropdownButton } from 'react-bootstrap';
import { Typeahead, Highlighter, Token } from 'react-bootstrap-typeahead';
import { CheckSquare, Square } from 'react-bootstrap-icons';

const IngredientSearchForm = ({ onSubmit, brandOptions, tagOptions, ...props }) => {
  const [slug, setSlug] = useState('');
  const [tags, doSetTags] = useState([]);
  const [inciName, setInciName] = useState('');
  const [skinParam, setSkinParam] = useState('');
  const [tagJoinType, setTagJoinType] = useState('all');

  const setTags = (tags) => {
    const untagged = tags.find(({ name }) => name === '--UNTAGGED--');

    untagged ? doSetTags([untagged]) : doSetTags(tags);
  };

  const skinParamOptions = {
    tone: 'Skin tone',
    milieu: 'Skin milieu',
    hydration: 'Skin hydration',
    smoothness: 'Skin smoothness',
    dermalFibers: 'Dermal fibers',
    shineControl: 'Shine control',
    oxygenSaturation: 'Oxygen saturation',
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    e.stopPropagation();

    const tagNames = tags.map(({ name }) => name);

    onSubmit({ inciName, slug, tags: tagNames, tagJoinType, skinParam });
  };

  return (
    <Form onSubmit={handleSubmit} {...props}>
      <Form.Row>
        <Col sm="auto">
          <Form.Group>
            <Form.Control
              placeholder="INCI name"
              value={inciName}
              onChange={(e) => setInciName(e.target.value)}
            />
          </Form.Group>
        </Col>

        <Col sm="auto">
          <Form.Group>
            <Form.Control
              placeholder="Slug"
              value={slug}
              onChange={(e) => setSlug(e.target.value)}
            />
            <Form.Text className="text-muted">
              <b>*</b> - wild match (example: <b>*water</b>)
            </Form.Text>
          </Form.Group>
        </Col>

        <Col sm="auto">
          <Form.Group>
            <InputGroup>
              <Typeahead
                id="tags"
                multiple
                highlightOnlyResult
                selectHintOnEnter
                onChange={setTags}
                options={tagOptions}
                labelKey="name"
                placeholder="Claims"
                selected={tags}
                renderToken={(option, props, index) => {
                  return (
                    <Token key={index} onRemove={props.onRemove} option={option}>
                      {option.name} <small>({option.count.toLocaleString()})</small>
                    </Token>
                  );
                }}
                renderMenuItemChildren={(option, props) => {
                  return (
                    <Fragment key={option.name}>
                      <Highlighter search={props.text}>{option.name}</Highlighter>
                      <small> ({option.count.toLocaleString()})</small>
                    </Fragment>
                  );
                }}
              />

              <DropdownButton
                as={InputGroup.Append}
                variant="outline-secondary"
                title={
                  {
                    all: 'With all',
                    any: 'With any',
                    excludeAll: 'Without all',
                    excludeAny: 'Without any',
                  }[tagJoinType]
                }
              >
                <Dropdown.Item
                  disabled={tagJoinType === 'all'}
                  onClick={() => setTagJoinType('all')}
                >
                  {' '}
                  {tagJoinType === 'all' ? <CheckSquare /> : <Square />} <b>With</b>{' '}
                  {tags.length === 0
                    ? 'all'
                    : tags
                        .flatMap(({ name }) => [
                          <b key={`a-${name}`}> AND </b>,
                          <span key={`b-${name}`}>"{name}"</span>,
                        ])
                        .slice(1)}
                </Dropdown.Item>
                <Dropdown.Item
                  disabled={tagJoinType === 'any'}
                  onClick={() => setTagJoinType('any')}
                >
                  {' '}
                  {tagJoinType === 'any' ? <CheckSquare /> : <Square />} <b>With</b>{' '}
                  {tags.length === 0
                    ? 'any'
                    : tags
                        .flatMap(({ name }) => [
                          <b key={`a-${name}`}> OR </b>,
                          <span key={`b-${name}`}>"{name}"</span>,
                        ])
                        .slice(1)}
                </Dropdown.Item>
                <Dropdown.Item
                  disabled={tagJoinType === 'excludeAll'}
                  onClick={() => setTagJoinType('excludeAll')}
                >
                  {' '}
                  {tagJoinType === 'excludeAll' ? <CheckSquare /> : <Square />} <b>Without</b>{' '}
                  {tags.length === 0
                    ? 'all'
                    : tags
                        .flatMap(({ name }) => [
                          <b key={`a-${name}`}> AND </b>,
                          <span key={`b-${name}`}>"{name}"</span>,
                        ])
                        .slice(1)}
                </Dropdown.Item>
                <Dropdown.Item
                  disabled={tagJoinType === 'excludeAny'}
                  onClick={() => setTagJoinType('excludeAny')}
                >
                  {' '}
                  {tagJoinType === 'excludeAny' ? <CheckSquare /> : <Square />} <b>Without</b>{' '}
                  {tags.length === 0
                    ? 'any'
                    : tags
                        .flatMap(({ name }) => [
                          <b key={`a-${name}`}> OR </b>,
                          <span key={`b-${name}`}>"{name}"</span>,
                        ])
                        .slice(1)}
                </Dropdown.Item>
              </DropdownButton>
            </InputGroup>
          </Form.Group>
        </Col>

        <Col sm="auto">
          <Form.Group>
            <InputGroup>
              <DropdownButton
                variant="outline-secondary"
                title={skinParamOptions[skinParam] || 'Select skin param'}
              >
                {skinParam === '' ? null : (
                  <Dropdown.Item onClick={() => setSkinParam('')}> --- Unselect --- </Dropdown.Item>
                )}

                {Object.entries(skinParamOptions).map(([k, v]) => (
                  <Dropdown.Item key={k} disabled={skinParam === k} onClick={() => setSkinParam(k)}>
                    {v}
                  </Dropdown.Item>
                ))}
              </DropdownButton>
            </InputGroup>
          </Form.Group>
        </Col>

        <Col sm="auto">
          <Form.Group>
            <Button type="submit">Search</Button>
          </Form.Group>
        </Col>
      </Form.Row>
    </Form>
  );
};

export default IngredientSearchForm;
