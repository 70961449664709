import React, { memo } from 'react';

const Misc = ({ misc }) =>
  misc.map(({ key, label, value }) => [
    <small key="name">
      <code>{key}</code> | {label}
    </small>,

    <p key="value" className="small">
      {value}
    </p>,
  ]);

export default memo(Misc);
