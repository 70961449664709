import React, { memo } from 'react';
import { Table } from 'react-bootstrap';
import { CircleFill, Circle } from 'react-bootstrap-icons';
import classnames from 'classnames';

import { unique } from '^/helpers/array';

import TagBadge from '^/components/tag-badge';

const IngredientClaimsTable = ({ data, onChange, className }) => {
  console.log('claims', data);

  const uniqueClaims = unique(
    Object.values(data)
      .map(({ claims }) => claims)
      .flat(),
  ).sort();

  const shouldRotateClaims = uniqueClaims.length > 5;

  return (
    <Table bordered striped className={classnames(className)}>
      <thead>
        <tr>
          <th></th>

          {uniqueClaims.map((claim) => (
            <th key={claim} className="text-center">
              <TagBadge className={shouldRotateClaims ? 'vertical-text' : null}>{claim}</TagBadge>
            </th>
          ))}
        </tr>
      </thead>

      <tbody>
        {data.map(({ key, name, claims }) => (
          <tr key={key} className={key === 'current' ? 'table-info' : null}>
            <td>{name}</td>

            {uniqueClaims.map((claim) => (
              <td
                key={claim}
                style={key === 'current' ? {} : { opacity: 0.4 }}
                className="text-center"
              >
                {claims.indexOf(claim) > -1 ? (
                  <CircleFill
                    onClick={() => onChange({ key, claim, value: false })}
                    className="text-success"
                  />
                ) : (
                  <Circle onClick={() => onChange({ key, claim, value: true })} />
                )}
              </td>
            ))}
          </tr>
        ))}
      </tbody>
    </Table>
  );
};

export default memo(IngredientClaimsTable);
