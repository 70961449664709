import React, { useRef, useState, useCallback, useEffect } from 'react';
import { Modal, Button } from 'react-bootstrap';
import { ChevronUp, ChevronDown } from 'react-bootstrap-icons';

import datumAPI from '^/api/datum';

import { ProductForm } from '^/components/forms';

import useSourceProducts from './hooks/use-source-products';

import SourceProductCard from './components/source-product-card';

function ProductModal({ product, onHide }) {
  const messageTimerRef = useRef();
  const [tagOptions, setTagOptions] = useState([]);
  const [isProcessing, setProcessing] = useState(false);
  const [lastSaveStatus, setLastSaveStatus] = useState(null);
  const [isSourceGroupsVisible, setSourceGroupsVisible] = useState(false);

  const sourceProducts = useSourceProducts(product.sourceProducts);

  const handleSubmit = useCallback(
    async (attrs) => {
      setProcessing(true);

      try {
        await datumAPI.updateProduct(product.uid, attrs);

        setLastSaveStatus('success');
      } catch (error) {
        console.log(error);

        setLastSaveStatus('error');
      } finally {
        setProcessing(false);

        messageTimerRef.current = setTimeout(() => setLastSaveStatus(null), 3000);
      }
    },
    [product.uid, setProcessing, setLastSaveStatus],
  );

  useEffect(() => {
    async function fetchData() {
      const { data } = await datumAPI.listProductTags();

      setTagOptions(data.map((tag) => tag.name));
    }

    fetchData();
  }, []);

  useEffect(() => {
    return () => clearTimeout(messageTimerRef.current);
  }, []);

  return (
    <Modal size="xl" show={true} onHide={onHide}>
      <Modal.Header closeButton>
        <Modal.Title as="h5">Product details</Modal.Title>
      </Modal.Header>

      <Modal.Body>
        <ProductForm
          product={product}
          onSubmit={handleSubmit}
          disabled={isProcessing}
          tagOptions={tagOptions}
          isProcessing={isProcessing}
          lastSaveStatus={lastSaveStatus}
        />
      </Modal.Body>

      <Modal.Footer className="d-block">
        <Button
          variant="link"
          onClick={() => setSourceGroupsVisible(!isSourceGroupsVisible)}
          className="text-muted px-0"
        >
          {isSourceGroupsVisible ? (
            <>
              Hide sources <ChevronUp key="chu" />
            </>
          ) : (
            <>
              Show sources <ChevronDown key="chd" />
            </>
          )}
        </Button>

        {isSourceGroupsVisible
          ? sourceProducts.map((sourceProduct) => (
              <SourceProductCard
                key={sourceProduct.uid}
                className="mb-3"
                sourceProduct={sourceProduct}
              />
            ))
          : null}
      </Modal.Footer>
    </Modal>
  );
}

export default ProductModal;
