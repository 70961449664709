import React, { Component } from 'react';
import { Container, Row, Col } from 'react-bootstrap';

import datumAPI from '^/api/datum';

import { ProductsTable } from '^/components/tables';
import { ProductSearchForm } from '^/components/forms';
import { MergeProductsModal } from '^/components/modals';

import TableNavigation from '../table-navigation';

class ProductsDashboard extends Component {
  constructor(props) {
    super(props);

    this.state = {
      tags: [],
      brands: [],
      filter: {},
      channel: null,
      products: [],
      isLoading: true,
      pagination: {
        next: null,
        prev: null,
        totalCount: null,
        totalCountCapped: false,
      },
      productPulls: [],
      mergeProducts: [],
      isMergeProductsModalVisible: false,
    };
  }

  async componentDidMount() {
    this.joinChannel();

    const { filter } = this.state;

    const [{ data: brands }, { data: tags }, { data: productPulls }] = await Promise.all([
      datumAPI.listProductBrands(),
      datumAPI.listProductTags(),
      datumAPI.listProductPulls(),
      this.load({ filter }),
    ]);

    productPulls.forEach(
      (p, index) =>
        (productPulls[index] = {
          date: new Date(p.date),
          count: p.count,
        }),
    );

    this.setState({ brands, tags, productPulls });
  }

  componentWillUnmount() {
    const { channel } = this.state;

    channel.leave();
  }

  joinChannel = () => {
    const socket = datumAPI.getSocket();
    const channel = socket.channel('products');

    this.setState({ channel });

    channel
      .join()
      .receive('error', ({ reason }) => console.log('Failed join "products" channel', reason))
      .receive('timeout', () => console.log('Networking issue. Still waiting...'));

    channel.on('product_updated', ({ data: updatedProduct }) => {
      const { products } = this.state;
      const idx = products.map(({ uid }) => uid).indexOf(updatedProduct.uid);

      if (idx > -1) {
        const newProducts = [...products];
        newProducts[idx] = updatedProduct;
        this.setState({ products: newProducts });
      }
    });

    channel.on('products_removed', ({ data: uids }) => {
      const { products } = this.state;
      const newProducts = products.filter((p) => !uids.includes(p.uid));

      this.setState({ products: newProducts });
    });
  };

  toggleMergeProduct = (product) => {
    const { mergeProducts } = this.state;

    const uids = mergeProducts.map((p) => p.uid);

    if (uids.includes(product.uid)) {
      const filteredProducts = mergeProducts.filter((p) => p.uid !== product.uid);
      this.setState({ mergeProducts: filteredProducts });
    } else {
      this.setState({ mergeProducts: [...mergeProducts, product] });
    }
  };

  handleMergeProductModalHide = (reason) => {
    const newState = {
      isMergeProductsModalVisible: false,
    };

    if (reason === 'merged') {
      newState.mergeProducts = [];
    }

    this.setState(newState);
  };

  load = async ({ cursor, filter }) => {
    this.setState({ isLoading: true });

    const { data: products, pagination } = await datumAPI.listProducts({ cursor, filter });

    this.setState({
      isLoading: false,
      pagination,
      products,
    });
  };

  handleSearchFormSubmit = ({
    tags,
    name,
    slug,
    brand,
    category,
    archived,
    tagJoinType,
    productPull: productPull,
  }) => {
    const map = {
      all: 'tagsAll',
      any: 'tagsAny',
      excludeAll: 'tagsExcludeAll',
      excludeAny: 'tagsExcludeAny',
    };
    const tagsKey = map[tagJoinType];

    const filter = {
      name,
      slug,
      brand,
      category,
      archived,
      [tagsKey]: tags,
      lastSeenAt: productPull?.date,
    };

    this.setState({ filter });

    this.load({ filter });
  };

  onChangePage = (cursor) => {
    const { filter } = this.state;

    this.load({ cursor, filter });
  };

  onMergeProductsClick = () => {
    this.setState({ isMergeProductsModalVisible: true });
  };

  onUnselectProductsClick = () => {
    this.setState({ mergeProducts: [] });
  };

  mergeProducts = (primaryProduct, slaveProducts) => {
    return datumAPI.mergeProducts(primaryProduct, slaveProducts);
  };

  render() {
    const {
      tags,
      brands,
      products,
      isLoading,
      pagination,
      productPulls,
      mergeProducts,
      isMergeProductsModalVisible,
    } = this.state;

    const mergeProductUids = mergeProducts.map((p) => p.uid);

    return (
      <Container fluid>
        <Row>
          <Col>
            <ProductSearchForm
              onSubmit={this.handleSearchFormSubmit}
              tagOptions={tags || []}
              brandOptions={brands}
              productPulls={productPulls}
            />
          </Col>
        </Row>

        <Row>
          <Col>
            {isLoading ? (
              'Loading...'
            ) : (
              <>
                <div className="sticky-top bg-white pt-1 pb-2 border-bottom" style={{ zIndex: 10 }}>
                  <TableNavigation
                    pagination={pagination}
                    mergeProducts={mergeProducts}
                    onPageChange={this.onChangePage}
                    onMergeProductsClick={this.onMergeProductsClick}
                    onUnselectProductsClick={this.onUnselectProductsClick}
                  />
                </div>

                <ProductsTable
                  products={products}
                  onSelectProduct={this.toggleMergeProduct}
                  mergeProductUids={mergeProductUids}
                />
              </>
            )}
          </Col>
        </Row>

        {isMergeProductsModalVisible && (
          <MergeProductsModal
            onHide={this.handleMergeProductModalHide}
            products={mergeProducts}
            mergeProducts={this.mergeProducts}
          />
        )}
      </Container>
    );
  }
}

export default ProductsDashboard;
