import React from 'react';
import { Navbar, Nav } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import classnames from 'classnames';

import { NAVIGATION_LINKS } from './constants';

const MainMenu = () => (
  <Navbar bg="light" expand="lg" className="mb-3">
    <Navbar.Toggle aria-controls="basic-navbar-nav" />

    <Navbar.Collapse id="basic-navbar-nav">
      <Nav className="mr-auto">
        {NAVIGATION_LINKS.map(({ id, to, name, className }) => (
          <Link key={id} to={to} className={classnames('nav-link', className)}>
            {name}
          </Link>
        ))}
      </Nav>
    </Navbar.Collapse>
  </Navbar>
);

export default MainMenu;
