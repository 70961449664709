import React, { memo } from 'react';
import { Form, Button, InputGroup } from 'react-bootstrap';

const SearchStringInput = ({ slug, setSlug, setIsFormExpanded }) => (
  <Form.Group controlId="formProductSlug">
    <InputGroup>
      <Form.Control
        value={slug}
        onChange={(e) => setSlug(e.target.value)}
        onKeyDown={(e) => setIsFormExpanded(e.key === 'Tab')}
        placeholder="Search products... (Press Tab key for more options)"
      />

      <InputGroup.Append>
        <Button type="submit" variant="outline-secondary">
          ↳
        </Button>
      </InputGroup.Append>
    </InputGroup>
  </Form.Group>
);

export default memo(SearchStringInput);
