import React, { memo } from 'react';
import { Form } from 'react-bootstrap';

const ProductPullIndexSelect = ({ productPullIndex, setProductPullIndex, productPulls }) => (
  <Form.Group>
    <Form.Control
      as="select"
      value={productPullIndex}
      onChange={(e) => setProductPullIndex(e.target.value)}
      placeholder="Last seen at"
    >
      <option value="-1">Last seen at</option>

      {productPulls.map(({ date, count: qtt }, idx) => {
        const text = `${date.toLocaleDateString('en-US', {
          day: 'numeric',
          month: 'short',
          year: 'numeric',
        })} ${qtt.toLocaleString()}`;

        return (
          <option key={idx} value={idx}>
            {text}
          </option>
        );
      })}
    </Form.Control>
  </Form.Group>
);

export default memo(ProductPullIndexSelect);
