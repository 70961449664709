import React, { memo } from 'react';
import { Col, Form } from 'react-bootstrap';
import { Typeahead } from 'react-bootstrap-typeahead';

import ChangelogInfoIcon from '../changelog-info-icon';

const Tags = ({ disabled, setProductTags, tagOptions, productTags }) => (
  <Form.Group controlId="productTags" as={Form.Row}>
    <Form.Label column sm={4} md={3} xl={2}>
      Tags <ChangelogInfoIcon />
    </Form.Label>

    <Col>
      <Typeahead
        id="productTags"
        allowNew={true}
        multiple={true}
        options={tagOptions}
        selected={productTags}
        disabled={disabled}
        onChange={setProductTags}
        placeholder="Choose product tags..."
      />
    </Col>
  </Form.Group>
);

export default memo(Tags);
