import React, { memo } from 'react';

import ScheduleButton from '../schedule-button';

const TaskRow = ({ task, onSchedule }) => {
  const { name, description, status, lastExitReason } = task;
  const isLastFail = lastExitReason === 'unexpected';
  const isScheduled = status === 'scheduled';

  return (
    <tr className={isScheduled ? 'table-warning' : isLastFail ? 'table-danger' : null}>
      <td>
        <ScheduleButton task={task} onClick={() => onSchedule(task)} />
      </td>

      <td className="text-nowrap">{name}</td>

      <td>{description}</td>

      <td>
        <span className={{ idle: 'text-success' }[status]}>{status}</span>
      </td>

      <td>{lastExitReason || 'n/a'}</td>
    </tr>
  );
};

export default memo(TaskRow);
