import React, { Fragment } from 'react';

import TagBadge from '^/components/tag-badge';

const TagList = ({ tags, variant, renderEmpty = true, ...props }) => {
  if (Array.isArray(tags) && tags.length > 0) {
    return tags.sort().map((tag) => (
      <Fragment key={tag}>
        <TagBadge variant={variant || 'dark'} {...props}>
          {tag}
        </TagBadge>{' '}
      </Fragment>
    ));
  } else if (renderEmpty) {
    return (
      <TagBadge variant={variant || 'light'} {...props}>
        n/a
      </TagBadge>
    );
  }

  return null;
};

export default TagList;
