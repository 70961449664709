import React from 'react';
import { ListGroup, Badge } from 'react-bootstrap';

const QueueStatus = ({ stats }) => (
  <ListGroup className="mb-3">
    <ListGroup.Item variant="secondary">
      <b>Queue status</b>
    </ListGroup.Item>

    {stats.map(({ queue, messageCount, consumerCount }) => (
      <ListGroup.Item key={queue} className="d-flex justify-content-between align-items-center">
        {queue} (cons: {consumerCount.toLocaleString()})
        <Badge variant="primary" pill>
          {messageCount.toLocaleString()}
        </Badge>
      </ListGroup.Item>
    ))}
  </ListGroup>
);

export default QueueStatus;
