import React from 'react';
import { Card } from 'react-bootstrap';

const ProductCard = ({ product, onClick, isPrimary }) => {
  const { name, brand } = product;

  return (
    <Card
      style={{ width: '200px' }}
      border={isPrimary && 'primary'}
      onClick={() => onClick(product)}
    >
      <Card.Img
        src={product.coverImageUrl}
        style={{ maxHeight: '200px', objectFit: 'contain', padding: '20px' }}
        variant="top"
      />

      <Card.Body className="text-center">
        <Card.Title>{name}</Card.Title>

        <Card.Subtitle>{brand}</Card.Subtitle>
      </Card.Body>
    </Card>
  );
};

export default ProductCard;
