import React, { memo, useState } from 'react';
import { Form, Row, Col } from 'react-bootstrap';

const FiltersForm = ({ filterParams, onChange }) => {
  const [priority, doSetPriority] = useState(filterParams.priority);
  const [exclude, doSetExclude] = useState(filterParams.exclude);

  function setPriority(priority) {
    const newFilterParams = { ...filterParams, priority };
    onChange(newFilterParams);
    doSetPriority(priority);
  }

  function setExclude(exclude) {
    const newFilterParams = { ...filterParams, exclude };
    onChange(newFilterParams);
    doSetExclude(exclude);
  }

  return (
    <Row>
      <Col>
        <Form.Group controlId="priority">
          <Form.Label className="small">Looking for</Form.Label>
          <Form.Control
            as="select"
            value={priority}
            onChange={(e) => setPriority(e.target.value)}
            size="sm"
          >
            <option value="">Anything</option>
            <option value="oil">Oil</option>
            <option value="mist">Mist</option>
            <option value="emulsion">Emulsion</option>
            <option value="essence">Essence</option>
            <option value="lotion">Lotion</option>
            <option value="cream">Cream</option>
            <option value="serum">Serum</option>
            <option value="gel">Gel</option>
            <option value="mask">Mask</option>
            <option value="balm">Balm</option>
            <option value="butter">Butter</option>
            <option value="highlighter">Highlighter</option>
            <option value="primer">Primer</option>
            <option value="bb/cc">BB / CC</option>
            <option value="spf">SPF</option>
          </Form.Control>
        </Form.Group>
      </Col>

      <Col>
        <Form.Group controlId="exclude">
          <Form.Label className="small">Dont want</Form.Label>

          <Form.Control
            as="select"
            value={exclude}
            onChange={(e) => setExclude(e.target.value)}
            size="sm"
          >
            <option value="">---</option>
            <option value="spf">SPF</option>
            <option value="fragrance">Fragrance</option>
            <option value="allergen">Allergen</option>
          </Form.Control>
        </Form.Group>
      </Col>
    </Row>
  );
};

export default memo(FiltersForm);
