import React, { memo } from 'react';
import { Col, Form } from 'react-bootstrap';

import ChangelogInfoIcon from '../changelog-info-icon';

const Field = ({
  value,
  label,
  onChange = null,
  withIcon = false,
  readOnly = false,
  controlId,
  placeholder,
  ...controlProps
}) => {
  return (
    <Form.Group controlId={controlId} as={Form.Row}>
      <Form.Label column sm={4} md={3} xl={2}>
        {label} {withIcon && <ChangelogInfoIcon />}
      </Form.Label>

      <Col>
        <Form.Control
          {...controlProps}
          value={value}
          readOnly={readOnly}
          onChange={onChange}
          placeholder={placeholder}
        />
      </Col>
    </Form.Group>
  );
};

export default memo(Field);
