import React, { memo, useState } from 'react';
import { Row, Col } from 'react-bootstrap';

import RangeInput from './components/range-input';

const features = [
    "active-acne",
    "bitterness-lines",
    "clogged-pores",
    "coarse-skin",
    "coef-A_low",
    "corneometer_low",
    "dryness-lines",
    "duec-hemoglobin",
    "duec-melanin",
    "expression-lines",
    "forehead-lines",
    "hbt1",
    "hbt2",
    "ph",
    "photoaged-skin",
    "phototype",
    "phototype_low",
    "pigmentation_A",
    "pigmentation_I",
    "pih",
    "pores_A",
    "pores_I",
    "porphyrin_A",
    "porphyrin_I",
    "redness_A",
    "redness_I",
    "sagging",
    "sebum",
    "sebum_low",
    "sensitive-skin",
    "sto2_low",
    "sun-damage",
    "temperature",
    "tewl",
    "texture",
    "undereye-bags",
    "uv-exposure",
    "veins",
    "wrinkles_A",
    "wrinkles_I"
]

const FeatureProfileForm = ({ skinFeatures: initialSkinFeatures, onChange, disabled }) => {
  const [skinFeatures, setSkinFeatures] = useState(initialSkinFeatures);

  function setSkinFeatureParam(paramName, value) {
    const newSkinFeatures = { ...skinFeatures, [paramName]: parseInt(value) };
    setSkinFeatures(newSkinFeatures);
    onChange(newSkinFeatures);
  }

  return (
    <Row>
    {
      features.map(function(feature_name) {
        return (
        <Col>
          <RangeInput
            title={`${feature_name} (${skinFeatures[feature_name]})`}
            labels={['Min', 'Max']}
            value={skinFeatures[feature_name]}
            onChange={(v) => setSkinFeatureParam(feature_name, v)}
            disabled={disabled}
          />
        </Col>
        );
      })
    }
    </Row>
  );
};

export default memo(FeatureProfileForm);
