import React, { useState, useEffect, useRef } from 'react';
import {
  Form,
  Row,
  Col,
  Button,
  Spinner,
  Dropdown,
  InputGroup,
  ButtonGroup,
} from 'react-bootstrap';
import { ChevronDown, ChevronUp, Check, FlagFill } from 'react-bootstrap-icons';

import TagList from '^/components/tag-list';
import TagBadge from '^/components/tag-badge';
import {
  IngredientClaimsTable,
  IngredientInciNameTable,
  IngredientDescriptionTable,
} from '^/components/tables';

function IngredientForm({ ingredient, sourceGroups, message, onSubmit, ...props }) {
  const { slug } = ingredient;

  const [note, setNote] = useState(ingredient.note || '');
  const [claims, setClaims] = useState([...(ingredient.claims || [])]);
  const [flagged, setFlagged] = useState(!!ingredient.flagged);
  const [inciName, setInciName] = useState(ingredient.inciName || '');
  const [verified, setVerified] = useState(!!ingredient.verified);
  const [submitMode, setSubmitMode] = useState(verified ? 'save' : 'verifyAndSave');
  const [description, setDescription] = useState(ingredient.description || '');

  const [isClaimsTableVisible, setIsClaimsTableVisible] = useState(false);
  const [isInciNameTableVisible, setIsInciNameTableVisible] = useState(false);
  const [isDescriptionTableVisible, setIsDescriptionTableVisible] = useState(false);

  const inciNameInputRef = useRef(null);
  const descriptionInputRef = useRef(null);

  const claimsData = [{ key: 'current', name: 'Current', claims }, ...sourceGroups];

  useEffect(() => {
    if (flagged) {
      setVerified(false);
      setSubmitMode('save');
    } else {
      setNote('');
    }
  }, [flagged]);

  useEffect(() => {
    if (verified) {
      setFlagged(false);
    }
  }, [verified]);

  function handleInciNameChange({ inciName }) {
    setInciName(inciName);
    setIsInciNameTableVisible(false);

    inciNameInputRef.current.focus();
  }

  function handleDescriptionChange({ description }) {
    setDescription(description);
    setIsDescriptionTableVisible(false);

    descriptionInputRef.current.focus();
  }

  function handleClaimChange({ key, claim, value }) {
    if (key !== 'current') {
      return;
    }

    const idx = claims.indexOf(claim);

    if (idx > -1 && value === false) {
      const newClaims = [...claims];
      newClaims.splice(idx, 1);
      setClaims(newClaims);
    } else if (idx === -1 && value === true) {
      setClaims([...claims, claim]);
    }
  }

  function handleSubmit(e) {
    e.preventDefault();
    e.stopPropagation();

    const attrs = { inciName, description, verified, claims, slug, flagged, note };

    if (submitMode === 'verifyAndSave') {
      attrs.verified = true;
      setVerified(attrs.verified);
    }

    onSubmit(attrs);
  }

  return (
    <Form onSubmit={handleSubmit}>
      <fieldset disabled={props.disabled}>
        <Form.Group controlId="inciName" as={Form.Row}>
          <Form.Label column sm={3} md={2} xl={1}>
            INCI name
          </Form.Label>
          <Col>
            <InputGroup>
              <Form.Control
                ref={inciNameInputRef}
                placeholder="INCI name"
                value={inciName}
                onChange={(e) => setInciName(e.target.value)}
              />
              <InputGroup.Append>
                <Button
                  disabled={props.disabled}
                  variant="outline-secondary"
                  onClick={() => setIsInciNameTableVisible(!isInciNameTableVisible)}
                >
                  {isInciNameTableVisible ? <ChevronUp /> : <ChevronDown />}
                </Button>
              </InputGroup.Append>
            </InputGroup>
            {isInciNameTableVisible && (
              <IngredientInciNameTable
                onClick={handleInciNameChange}
                className="mt-3"
                ingredient={ingredient}
                sourceGroups={sourceGroups}
              />
            )}
          </Col>
        </Form.Group>

        <Form.Group controlId="slug" as={Row}>
          <Form.Label column sm={3} md={2} xl={1}>
            Slug
          </Form.Label>
          <Col>
            <Form.Control placeholder="Slug" readOnly value={slug} />
          </Col>
        </Form.Group>

        <Form.Group controlId="claims" as={Row}>
          <Form.Label column sm={3} md={2} xl={1}>
            Claims
          </Form.Label>
          <Col>
            <p className="mb-0 pt-1">
              <TagList tags={claims} disabled={props.disabled} />{' '}
              <TagBadge
                variant="primary"
                style={{ cursor: 'default' }}
                onClick={() => setIsClaimsTableVisible(!isClaimsTableVisible)}
              >
                {isClaimsTableVisible
                  ? ['hide table ', <ChevronUp key="chup" />]
                  : ['view table ', <ChevronDown key="chdown" />]}
              </TagBadge>
            </p>
            {isClaimsTableVisible ? (
              <IngredientClaimsTable
                data={claimsData}
                onChange={(e) => {
                  !props.disabled && handleClaimChange(e);
                }}
                className="mt-3"
              />
            ) : null}
          </Col>
        </Form.Group>

        <Form.Group controlId="description" as={Row}>
          <Form.Label column sm={3} md={2} xl={1}>
            Description
          </Form.Label>
          <Col>
            <InputGroup>
              <Form.Control
                ref={descriptionInputRef}
                placeholder="Description"
                as="textarea"
                rows="5"
                style={{ resize: 'none' }}
                value={description}
                onChange={(e) => setDescription(e.target.value)}
              />
              <InputGroup.Append>
                <Button
                  disabled={props.disabled}
                  variant="outline-secondary"
                  onClick={() => setIsDescriptionTableVisible(!isDescriptionTableVisible)}
                >
                  {isDescriptionTableVisible ? <ChevronUp /> : <ChevronDown />}
                </Button>
              </InputGroup.Append>
            </InputGroup>

            {isDescriptionTableVisible && (
              <IngredientDescriptionTable
                onClick={handleDescriptionChange}
                className="mt-3"
                ingredient={ingredient}
                sourceGroups={sourceGroups}
              />
            )}
          </Col>
        </Form.Group>

        <hr />

        <Form.Group controlId="verified" as={Row}>
          <Col sm={{ offset: 3 }} md={{ offset: 2 }} xl={{ offset: 1 }}>
            <Form.Check
              className={verified ? 'text-success' : null}
              checked={verified}
              onChange={() => setVerified(!verified)}
              type="switch"
              label={verified ? ['Verified', <Check key="check" />] : 'Not verified'}
            />
          </Col>
        </Form.Group>

        <Form.Group controlId="flagged" as={Row}>
          <Col sm={{ offset: 3 }} md={{ offset: 2 }} xl={{ offset: 1 }}>
            <Form.Check
              className={flagged ? 'text-danger' : null}
              checked={flagged}
              onChange={() => setFlagged(!flagged)}
              type="switch"
              label={
                flagged
                  ? ['Flagged', <FlagFill key="flag" className="text-danger" />]
                  : 'Not flagged'
              }
            />
          </Col>
        </Form.Group>

        {flagged ? (
          <Form.Group controlId="note" as={Form.Row}>
            <Form.Label column sm={3} md={2} xl={1}>
              Note
            </Form.Label>

            <Col>
              <Form.Control
                value={note}
                onChange={(e) => setNote(e.target.value)}
                placeholder="Note (visible to admins only)"
              />
            </Col>
          </Form.Group>
        ) : null}

        <Row>
          <Col sm={{ offset: 3 }} md={{ offset: 2 }} xl={{ offset: 1 }}>
            <Dropdown as={ButtonGroup} alignRight>
              <Button
                variant={submitMode === 'verifyAndSave' ? 'success' : 'outline-success'}
                style={{ minWidth: '140px' }}
                type="submit"
              >
                {props.processing
                  ? [
                      <Spinner animation="grow" as="span" size="sm" key="spinner" />,
                      ' Processing...',
                    ]
                  : { verifyAndSave: 'Verify and Save', save: 'Save' }[submitMode]}
              </Button>

              <Dropdown.Toggle
                split
                variant={submitMode === 'verifyAndSave' ? 'success' : 'outline-success'}
                id="submitButton"
              />

              <Dropdown.Menu>
                <Dropdown.Item onClick={() => setSubmitMode('verifyAndSave')} disabled={flagged}>
                  Verify and Save
                </Dropdown.Item>
                <Dropdown.Item onClick={() => setSubmitMode('save')}>Save</Dropdown.Item>
              </Dropdown.Menu>
            </Dropdown>

            {message[0] ? (
              <span
                className={`${{ success: 'text-muted', error: 'text-danger' }[message[0]]} ml-3`}
              >
                {message[1]}
              </span>
            ) : null}
          </Col>
        </Row>
      </fieldset>
    </Form>
  );
}

export default IngredientForm;
