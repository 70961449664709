import React, { memo } from 'react';
import { Form } from 'react-bootstrap';

import { ARCHIVED_STATES_MAP } from '../../constants';

const ArchivedSelect = ({ archived, setArchived }) => (
  <Form.Group>
    <Form.Control as="select" value={archived} onChange={(e) => setArchived(e.target.value)}>
      <option value={ARCHIVED_STATES_MAP.ALL}>Archived + Unarchived</option>
      <option value={ARCHIVED_STATES_MAP.ARCHIVED}>Archived only</option>
      <option value={ARCHIVED_STATES_MAP.UNARCHIVED}>Unarchived only</option>
    </Form.Control>
  </Form.Group>
);

export default memo(ArchivedSelect);
