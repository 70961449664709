import React, { useState, useEffect } from 'react';
import { Col, Row, Container } from 'react-bootstrap';
import { Chart } from 'react-google-charts';

import datumAPI from '^/api/datum';

const ProductIngredientsCoverageStats = () => {
  const [{ data, isLoading }, setState] = useState({
    data: [],
    isLoading: true,
  });

  useEffect(() => {
    const fetchData = async () => {
      try {
        const { data } = await datumAPI.getProductIngredientsCoverageStats();

        setState({
          data,
          isLoading: false,
        });
      } catch {
        setState({
          data: [],
          isLoading: false,
        });
      }
    };

    fetchData();
  }, []);

  const chartData = data.map(([k, v]) => [k === 0 ? '0%' : `${k - 9}-${k}%`, v, v]).reverse();

  if (isLoading) {
    return <p className="mt-5 text-center">Loading...</p>;
  }

  return (
    <Container>
      <Row>
        <Col>
          <Chart
            data={[['Coverage', 'Products', { role: 'annotation' }], ...chartData]}
            width="100%"
            height="80vh"
            options={{
              title: 'Product ingredients coverage',
              vAxis: {
                title: 'Per product ingredient coverage',
              },
              hAxis: {
                title: 'Products count',
                logScale: true,
              },
            }}
            chartType="BarChart"
          />
        </Col>
      </Row>
    </Container>
  );
};

export default ProductIngredientsCoverageStats;
