import React from 'react';
import { Table } from 'react-bootstrap';

import ProductRow from './components/product-row';

const ProductsTable = ({ products, onSelectProduct, mergeProductUids }) => (
  <Table responsive bordered striped size="sm" className="mt-2">
    <thead>
      <tr>
        <th></th>
        <th className="text-center">#</th>
        <th>Image</th>
        <th>Brand</th>
        <th>Name</th>
        <th>Category</th>
        <th>Tags</th>
        <th>Macy's product</th>
        <th>Sephora product</th>
        <th>Ulta product</th>
        <th>Nordstrom product</th>
        <th>Dermstore product</th>
      </tr>
    </thead>

    <tbody>
      {products.map((product, index) => (
        <ProductRow
          key={product.uid}
          product={product}
          onCheck={onSelectProduct}
          isChecked={mergeProductUids.includes(product.uid)}
          rowNumber={index + 1}
        />
      ))}
    </tbody>
  </Table>
);

export default ProductsTable;
