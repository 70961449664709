import React, { memo } from 'react';
import { Badge } from 'react-bootstrap';

export function TagBadge({ variant, children, className, ...props }) {
  const mergedClassName = [];

  if (Array.isArray(className)) {
    className.forEach(mergedClassName.push);
  }
  if (typeof className === 'string') {
    mergedClassName.push(className);
  }

  mergedClassName.push('badge-tag');

  if (props.disabled) {
    mergedClassName.push('disabled');
  }

  return (
    <Badge variant={variant || 'dark'} className={mergedClassName.join(' ')} {...props}>
      {children}
    </Badge>
  );
}

export default memo(TagBadge);
