import React, { memo } from 'react';
import { Modal, ListGroup } from 'react-bootstrap';

import TagList from '^/components/tag-list';

function Matches({ matches, ingredientSlug }) {
  return (
    <Modal.Body>
      <ListGroup>
        {matches.map((match, idx) => (
          <ListGroup.Item key={idx} variant={match.score === 1.0 ? 'success' : null}>
            <b>{match.inciName}</b>
            <br />
            <b>Slug:</b> {match.slug}
            <br />
            <b>Mapping:</b> <span className="text-muted">{ingredientSlug}</span> → {match.slug}
            <br />
            <b>Score:</b> {match.score.toFixed(2)}
            <br />
            <b>Claims:</b> <TagList tags={match.claims} variant="dark" />
          </ListGroup.Item>
        ))}
      </ListGroup>
    </Modal.Body>
  );
}

export default memo(Matches);
