import { useMemo } from 'react';

import { unique } from '^/helpers/array';

export default function useDescriptionOptions({ ingredient, sourceGroups }) {
  return useMemo(() => {
    const descriptions = sourceGroups
      .map(({ name, sources }) =>
        sources.map(({ inciName, sourceName, sourceUrl, description }) => ({
          inciName,
          description,
          sourceName: name,
          key: sourceName + '|' + sourceUrl,
        })),
      )
      .flat()
      .filter(({ description }) => !!description && description !== '');

    const currentDescription = {
      key: 'original',
      inciName: null,
      sourceName: 'Original',
      description: ingredient.description || '',
    };
    const uniqDescriptions = unique(descriptions, (el) => el.description);

    return [currentDescription, ...uniqDescriptions];
  }, [ingredient, sourceGroups]);
}
