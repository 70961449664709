import React from 'react';

import Router from '^/router';

import MainMenu from '^/components/main-menu';

function App() {
  return (
    <div className="App">
      <MainMenu />

      <Router />
    </div>
  );
}

export default App;
