import React from 'react';
import { Table } from 'react-bootstrap';

import InciNameRow from './components/inci-name-row';

const IngredientsTable = ({ ingredients }) => (
  <Table bordered striped hover size="sm">
    <thead>
      <tr>
        <th>INCI Name</th>
        <th>Slug</th>
        <th>Claims</th>
      </tr>
    </thead>

    <tbody>
      {ingredients.map((ingredient) => (
        <InciNameRow key={ingredient.slug} ingredient={ingredient} />
      ))}
    </tbody>
  </Table>
);

export default IngredientsTable;
