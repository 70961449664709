import { useState, useCallback } from 'react';

import { ARCHIVED_STATES_MAP } from '../constants';

export default function useCustomState() {
  const [state, setState] = useState({
    tags: [],
    slug: '',
    name: '',
    brand: '',
    archived: ARCHIVED_STATES_MAP.ALL,
    category: '',
    tagJoinType: 'all',
    isFormExpanded: false,
    productPullIndex: -1,
  });

  const useSetStateCallbackByPropertyName = (propertyName) =>
    useCallback((nextValue) =>
      setState(
        (prevProps) => ({
          ...prevProps,
          [propertyName]: nextValue,
        }),
        [setState],
      ),
    );

  const setTags = useSetStateCallbackByPropertyName('tags');
  const setName = useSetStateCallbackByPropertyName('name');
  const setSlug = useSetStateCallbackByPropertyName('slug');
  const setBrand = useSetStateCallbackByPropertyName('brand');
  const setArchived = useSetStateCallbackByPropertyName('archived');
  const setCategory = useSetStateCallbackByPropertyName('category');
  const setTagJoinType = useSetStateCallbackByPropertyName('tagJoinType');
  const setIsFormExpanded = useSetStateCallbackByPropertyName('isFormExpanded');
  const setProductPullIndex = useSetStateCallbackByPropertyName('productPullIndex');

  return {
    state,

    setTags,
    setName,
    setSlug,
    setBrand,
    setArchived,
    setCategory,
    setTagJoinType,
    setIsFormExpanded,
    setProductPullIndex,
  };
}
