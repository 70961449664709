import React, { memo } from 'react';
import { Table } from 'react-bootstrap';

import TaskRow from './components/task-row';

const TasksTable = ({ tasks, onSchedule }) => (
  <Table>
    <tbody>
      {Object.values(tasks).map((task) => (
        <TaskRow key={task.id} task={task} onSchedule={onSchedule} />
      ))}
    </tbody>
  </Table>
);

export default memo(TasksTable);
