import React, { memo } from 'react';
import { Form } from 'react-bootstrap';

const NameInput = ({ name, setName }) => (
  <Form.Group>
    <Form.Control value={name} onChange={(e) => setName(e.target.value)} placeholder="Name" />

    <Form.Text className="text-muted">
      <b>*</b> - wild match (example: <b>*oil</b>)
    </Form.Text>
  </Form.Group>
);

export default memo(NameInput);
