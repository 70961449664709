import React, { Fragment } from 'react';

import { BADGES } from './constants';

const ProductBadges = ({ product }) => (
  <small>
    {BADGES.map(
      ({ id, Icon, title, className, shouldRender }) =>
        shouldRender(product) && (
          <Fragment key={id}>
            <span title={title}>
              <Icon className={className} />
            </span>{' '}
          </Fragment>
        ),
    )}
  </small>
);

export default ProductBadges;
