import React, { memo } from 'react';

import { humanizeSkinParam } from '^/helpers/skin-parameters';

import SkinParameterBar from './components/skin-parameter-bar';

function SkinEffect({ vector, className, ...props }) {
  const cName = className ? 'progress-bar-skin-effect ' + className : 'progress-bar-skin-effect';

  return (
    <div className={cName} {...props}>
      {Object.entries(vector).map(([k, v]) => (
        <SkinParameterBar
          key={k}
          label={`${humanizeSkinParam(k)} (${v.toFixed(2)})`}
          value={v * 100}
        />
      ))}
    </div>
  );
}

export default memo(SkinEffect);
