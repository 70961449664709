import React, { memo } from 'react';
import { Card } from 'react-bootstrap';

import SkinEffect from '^/components/skin-effect';

const PerformanceCard = ({ performanceVector, ...props }) => {
  return (
    <Card {...props}>
      <Card.Header as="b">Skin effect</Card.Header>

      <Card.Body>
        <SkinEffect className="mt-3" vector={performanceVector} />
      </Card.Body>
    </Card>
  );
};

export default memo(PerformanceCard);
