import React, { memo } from 'react';
import { Card } from 'react-bootstrap';
import classnames from 'classnames';
import { BoxArrowUpRight } from 'react-bootstrap-icons';

import { humanizeSourceName } from '^/helpers/ingredient';

import TagList from '^/components/tag-list';

function SourceIngredientCard({
  className,
  sourceIngredient: { inciName, name, sourceName, sourceUrl, claimsRaw, claims, description },
}) {
  return (
    <Card className={classnames(className)}>
      <Card.Header>{humanizeSourceName(sourceName)}</Card.Header>

      <Card.Body>
        <Card.Title>{inciName}</Card.Title>

        <Card.Text>
          <b>Trade name: </b> {name}
          <br />
          <b>Claims: </b> <TagList tags={claimsRaw} variant="light" />
          <br />
          <b>Claims normalized: </b> <TagList tags={claims} />
          <br />
          <b>Description: </b>{' '}
          {description && description !== '' ? (
            <span style={{ whiteSpace: 'pre-wrap' }}>{description}</span>
          ) : (
            <small>
              <i>Empty</i>
            </small>
          )}
        </Card.Text>
      </Card.Body>

      <Card.Footer>
        <Card.Link href={sourceUrl} target="_blank" rel="noopener noreferrer">
          View source{' '}
          <sup>
            <BoxArrowUpRight />
          </sup>
        </Card.Link>
      </Card.Footer>
    </Card>
  );
}

export default memo(SourceIngredientCard);
