import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';

import datumAPI from '^/api/datum';

import { TasksTable } from '^/components/tables';

import QueueStatus from '../queue-status';

class PipelinesDashboard extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      tasks: [],
      stats: [],
      socket: null,
      channel: null,
      isConnected: false,
    };
  }

  componentDidMount() {
    const socket = datumAPI.getSocket();

    let channel = socket.channel('pipeline', {});

    channel
      .join()
      .receive('ok', ({ tasks }) => {
        this.setState({ isConnected: true, channel, tasks });
      })
      .receive('error', (resp) => {
        console.log('Unable to join', resp);
      });

    channel.on('tasks_update', ({ tasks }) => this.setState({ tasks }));
    channel.on('stats_update', ({ stats }) => this.setState({ stats }));

    this.setState({ socket, channel });
  }

  componentWillUnmount() {
    const { socket } = this.state;
    socket.disconnect();
  }

  scrape = ({ id }) => {
    const { channel } = this.state;

    channel.push('schedule', { id });
  };

  render() {
    const { stats, tasks, isConnected } = this.state;

    return (
      <Container fluid>
        {isConnected ? (
          <>
            <Row>
              <Col sm={12} lg={6}>
                <QueueStatus stats={stats} />
              </Col>
            </Row>

            <Row>
              <Col>
                <TasksTable tasks={tasks} onSchedule={this.scrape} />
              </Col>
            </Row>
          </>
        ) : (
          <Row>
            <Col>Connecting...</Col>
          </Row>
        )}
      </Container>
    );
  }
}

export default PipelinesDashboard;
