import React from 'react';

import TagBadge from '^/components/tag-badge';

import { VARIANTS_MAP } from './constants';

const CategoryBadge = ({ children: category }) => {
  const variant = VARIANTS_MAP[category] || 'secondary';

  return <TagBadge variant={variant}>{category}</TagBadge>;
};

export default CategoryBadge;
