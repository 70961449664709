import React, { memo, useState } from 'react';
import { Row, Col } from 'react-bootstrap';

import RangeInput from './components/range-input';

const SkinParamsForm = ({ skinProfile: initialSkinProfile, onChange, disabled }) => {
  const [skinProfile, setSkinProfile] = useState(initialSkinProfile);

  function setSkinParam(paramName, value) {
    const newSkinProfile = { ...skinProfile, [paramName]: parseInt(value) };
    setSkinProfile(newSkinProfile);
    onChange(newSkinProfile);
  }

  return (
    <Row>
      <Col>
        <RangeInput
          title={`Hydartion (${skinProfile.hydration})`}
          labels={['Unhydrated', 'Hydrated']}
          value={skinProfile.hydration}
          onChange={(v) => setSkinParam('hydration', v)}
          disabled={disabled}
        />
      </Col>
      <Col>
        <RangeInput
          title={`Tone (${skinProfile.tone})`}
          labels={['Uneven', 'Even']}
          value={skinProfile.tone}
          onChange={(v) => setSkinParam('tone', v)}
          disabled={disabled}
        />
      </Col>
      <Col>
        <RangeInput
          title={`Smoothness (${skinProfile.smoothness})`}
          labels={['Rough', 'Smooth']}
          onChange={(v) => setSkinParam('smoothness', v)}
          disabled={disabled}
        />
      </Col>
      <Col>
        <RangeInput
          title={`Dermal fibers (${skinProfile.dermalFibers})`}
          labels={['Lines/Wrinkles', 'Firm']}
          onChange={(v) => setSkinParam('dermalFibers', v)}
          disabled={disabled}
        />
      </Col>
      <Col>
        <RangeInput
          title={`Milieu (${skinProfile.milieu})`}
          labels={['Reactive', 'Resilient']}
          onChange={(v) => setSkinParam('milieu', v)}
          disabled={disabled}
        />
      </Col>
      <Col>
        <RangeInput
          title={`Oxygen sat. (${skinProfile.oxygenSaturation})`}
          labels={['Dull', 'Radiant']}
          onChange={(v) => setSkinParam('oxygenSaturation', v)}
          disabled={disabled}
        />
      </Col>
      <Col>
        <RangeInput
          title={`Shine control (${skinProfile.shineControl})`}
          labels={['Oily', 'Not Oily']}
          onChange={(v) => setSkinParam('shineControl', v)}
          disabled={disabled}
        />
      </Col>
    </Row>
  );
};

export default memo(SkinParamsForm);
