import React, { memo } from 'react';
import { Card } from 'react-bootstrap';
import { BoxArrowUpRight } from 'react-bootstrap-icons';
import classnames from 'classnames';

import { parseLastSeenAt } from '^/helpers/date';

import ProductIngredients from '^/components/product-ingredients';

import Misc from '../misc';

const SourceProductCard = ({ sourceProduct, className }) => {
  const {
    name,
    misc,
    brand,
    price,
    imageUrls,
    sourceUrl,
    sourceName,
    description,
    productIngredients,
  } = sourceProduct;

  const shouldRenderMisc = Array.isArray(misc) && misc.length > 0;

  return (
    <Card className={classnames(className)}>
      <Card.Header>{sourceName}</Card.Header>

      <Card.Body>
        <Card.Text as="div">
          <b>UID: </b> {sourceProduct.uid}
          <br />
          <b>Last seen at: </b> {parseLastSeenAt(sourceProduct.lastSeenAt)}
          <br />
          <b>Name: </b> {name}
          <br />
          <b>Brand: </b> {brand}
          <br />
          <b>Price: </b> {price ? `$${price.toFixed(2)}` : 'n/a'}
          <br />
          <br />
          <b>Description: </b>
          <br />{' '}
          {description ? (
            <span style={{ whiteSpace: 'pre-wrap' }}>{description}</span>
          ) : (
            <small>
              <i>Empty</i>
            </small>
          )}
          <br />
          <br />
          <b>Ingredients: </b>
          <br />
          <ProductIngredients ingredients={productIngredients} />
          <br />
          <br />
          <b>Images:</b>
          <br />
          <div className="d-flex mx-n2 mb-n2 align-items-start flex-wrap">
            {imageUrls &&
              imageUrls.map((imageUrl) => (
                <img
                  alt=""
                  key={imageUrl}
                  src={imageUrl}
                  style={{ maxHeight: '100px', maxWidth: '100px' }}
                  className="img-thumbnail d-block mx-2 mb-2"
                />
              ))}
          </div>
          <br />
          <b>Misc:</b>
          <br />
          {shouldRenderMisc ? (
            <Misc misc={misc} />
          ) : (
            <small>
              <i>Empty</i>
            </small>
          )}
        </Card.Text>
      </Card.Body>

      <Card.Footer>
        <Card.Link href={sourceUrl} target="_blank" rel="noopener noreferrer">
          View source{' '}
          <sup>
            <BoxArrowUpRight />
          </sup>
        </Card.Link>
      </Card.Footer>
    </Card>
  );
};

export default memo(SourceProductCard);
