import { useMemo } from 'react';

import { unique } from '^/helpers/array';

export default function useInciNameOptions({ ingredient, sourceGroups }) {
  return useMemo(() => {
    const inciNames = sourceGroups
      .map(({ name, sources }) =>
        sources.map(({ inciName, sourceName, sourceUrl }) => ({
          key: sourceName + '|' + sourceUrl,
          inciName,
          sourceName: name,
        })),
      )
      .flat()
      .filter(({ inciName }) => !!inciName && inciName !== '');

    const currentInciName = {
      key: 'original',
      inciName: ingredient.inciName,
      sourceName: 'Original',
    };

    const uniqInciNames = unique(inciNames, (el) => el.inciName);

    return [currentInciName, ...uniqInciNames];
  }, [ingredient, sourceGroups]);
}
